import * as yup from 'yup';

import { PlanTypeEnum } from 'pages/plans/utils';

const { BASE_PLAN, CUSTOM_PLAN, STORAGE_PLAN } = PlanTypeEnum;

export const PlansValidationSchema = yup.object({
  // Base Plan Validation
  monthlyAmount: yup.number().when('type', {
    is: BASE_PLAN,
    then: yup.number().required('Monthly Amount is required!'),
  }),
  annualAmount: yup.number().when('type', {
    is: BASE_PLAN,
    then: yup.number().required('Annual Amount is required!'),
  }),

  // Custom Plan Validation
  amount: yup.number().when('type', {
    is: CUSTOM_PLAN,
    then: yup.number().required('Amount is required!'),
  }),
  paymentFrequency: yup.string().when('type', {
    is: CUSTOM_PLAN,
    then: yup.string().required('Payment Frequency is required!'),
  }),
  userVisibility: yup.array().when('type', {
    is: CUSTOM_PLAN,
    then: yup.array().min(1, 'Plan Visible to Users field must have at least 1 item'),
  }),

  // Common Validation
  name: yup
    .string()
    .required('Name is required!')
    .min(2, 'First Name must contain at least 2 characters'),
  ofProjects: yup.number().when('type', {
    is: (value: string) => value === BASE_PLAN || value === CUSTOM_PLAN,
    then: yup.number().required('# Of Projects is required!'),
  }),
  ofUsers: yup.number().when('type', {
    is: (value: string) => value === BASE_PLAN || value === CUSTOM_PLAN,
    then: yup.number().required('# Of Users is required!'),
  }),
  storagePlan: yup.number().when('type', {
    is: (value: string) => value === CUSTOM_PLAN || value === STORAGE_PLAN,
    then: yup.number().required('Storage Plan is required!'),
  }),
});
