import { IconButton } from '@mui/material';

import { PopoverWrapper } from 'components/popovers/popover-wrapper';

import { ReactComponent as VerticalDotsIcon } from 'assets/icons/table-dots.svg';

import { popperStyles } from './styles';

type Props = {
  anchorEl: any;
  open: boolean;
  handleClose: any;
  handleOpen: any;
  children: any;
};

export const ActionsPopover: React.FC<Props> = ({
  open,
  anchorEl,
  handleClose,
  handleOpen,
  children,
}) => {
  return (
    <>
      <IconButton sx={popperStyles.iconBtn} onClick={handleOpen}>
        <VerticalDotsIcon />
      </IconButton>
      <PopoverWrapper
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
        customStyle={popperStyles.popover}
      >
        {children}
      </PopoverWrapper>
    </>
  );
};
