import { outlinedInputClasses, textFieldClasses } from '@mui/material';

export const autocompleteTextFieldStyles = {
  helperText: {
    margin: '5px 0',
    color: 'red',
  },

  input: {
    '& .MuiOutlinedInput-root': {
      padding: '6.5px 39px 6.5px 9px',
      height: '48px',
    },
    [`&.${textFieldClasses.root}`]: {
      '&:before': {
        border: 'unset !important',
      },
      '&:after': {
        border: 'unset !important',
      },
      '&:hover': {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          border: '1px solid #EDEFF7',
        },
      },

      '& .Mui-focused': {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: '#EDEFF7',
          borderWidth: '1px',
        },
      },
      '& .Mui-error': {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: '#EDEFF7',
        },
      },
      '& .Mui-disabled': {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: '#EDEFF7',
        },
      },
    },
    [`& .${outlinedInputClasses.input}`]: {
      fontSize: '14px',
      lineHeight: '146%',
      fontWeight: 400,
      color: '#23252F',
      fontFamily: 'Inter, sans-serif !important',
    },
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: '1px solid #EDEFF7',
      borderRadius: '12px',
      '&.Mui-error': {
        border: '1px solid #EDEFF7',
      },
    },
    '& .MuiAutocomplete-popupIndicator': {
      top: 'calc(50% - 11.5px)',
      right: '5px',
      transition: 'all 0.3s ease-in-out',
    },
    '& input::placeholder': {
      fontSize: '14px',
      lineHeight: '146%',
      color: '#8C8F9D',
      opacity: 1,
      fontFamily: 'Inter, sans-serif !important',
    },
  },
};
