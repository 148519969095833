export const accessDetailsStyles = {
  container: { width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' },
};

export const enterprisePlansStyles = {
  container: {
    width: '100%',
    padding: '14px',
    marginTop: '-5px',
    borderBottom: '1px solid #EDEFF7',
    borderRight: '1px solid #EDEFF7',
    borderLeft: '1px solid #EDEFF7',
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
};
