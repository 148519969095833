// api
import { api } from 'redux/api';
// types
import { GetUserParamsType } from './users-types';
import { IMeta, IUser, PaymentPlan } from 'utils/types';
import queryString from 'query-string';

import { providesList } from 'redux/helpers';

enum Endpoints {
  SUPER_ADMIN_USERS = 'super-admin/users',
  SUPER_ADMIN_LOGIN_AS_USER = 'super-admin/login-as-user',
  SUPER_ADMIN_PLANS = 'super-admin/plans',
  AUTH = 'auth/',
}

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<{ items: IUser[]; meta: IMeta }, GetUserParamsType>({
      query: ({ productIds, ...params }) => {
        const planIds = Array.isArray(productIds) && productIds.length ? productIds : null;

        const queryParams = planIds
          ? queryString.stringify({ productIds }, { arrayFormat: 'bracket' })
          : '';

        const url = `${Endpoints.SUPER_ADMIN_USERS}${queryParams ? `?${queryParams}` : ''}`;

        const paramsObj = Object.keys(params).length ? { params } : {};

        return {
          url,
          ...paramsObj,
        };
      },
      providesTags: (result, err) => (err ? [] : providesList(result?.items, 'Users')),
    }),
    updateUser: builder.mutation<IUser, { body: Partial<IUser>; userId: number }>({
      query: (data) => ({
        url: `${Endpoints.SUPER_ADMIN_USERS}/${data.userId}`,
        body: data.body,
        method: 'PATCH',
      }),
      invalidatesTags: (_, err, args) => {
        return !err ? [{ type: 'Users', id: args.userId }] : [];
      },
    }),
    loginAsUser: builder.mutation<any, { email: string }>({
      query: (data) => ({
        url: Endpoints.SUPER_ADMIN_LOGIN_AS_USER,
        body: data,
        method: 'POST',
      }),
    }),
    getPlans: builder.query<{ items: Array<PaymentPlan> }, void>({
      query: () => ({
        url: Endpoints.SUPER_ADMIN_PLANS,
      }),
    }),

    oneTimeEntry: builder.mutation({
      query: (data) => ({
        url: `${Endpoints.AUTH}one-time-entry`,
        body: data,
        method: 'POST',
      }),

      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          localStorage.setItem('userId', response?.data?.id?.toString());
          if (response?.data?.accessToken) {
            localStorage.setItem('Authorization', `Bearer ${response.data.accessToken}`);
          }
        } catch (err) {}
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLoginAsUserMutation,
  useGetPlansQuery,
  useUpdateUserMutation,
  useOneTimeEntryMutation,
} = usersApi;
