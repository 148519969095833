export enum PlanStatusEnum {
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
  'TRIALING' = 'TRIALING',
  'EXPIRED' = 'EXPIRED',
  'PENDING' = 'PENDING',
}

export enum PlanNameEnum {
  TRIAL = 'Trial',
  FIRST_PROJECT_FREE = 'First Project Free',
  LIFETIME_DEAL = 'Lifetime Deal',
}
