import React, { useCallback, useContext, useState, createContext, ReactNode } from 'react';
import { Alert, Snackbar } from '@mui/material';

type Toast = {
  type: 'success' | 'info' | 'warning' | 'error';
  message: string;
};

const ToastContext = createContext((_toast: Toast) => {});

export const ToastContextProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<Array<Toast & { id: string }>>([]);

  const addToast = useCallback(
    (toast: any) =>
      setToasts((prevState) => [
        ...prevState,
        { id: `${prevState.length + 1}-${Date.now()}`, ...toast },
      ]),
    [setToasts]
  );

  const onClose = (id: string) => {
    setToasts((prevState) => prevState.filter((toast) => id !== toast.id));
  };

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      {toasts.map(({ id, message, type }, index) => (
        <Snackbar
          key={index}
          open
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={5000}
          onClose={() => onClose(id)}
        >
          <Alert variant="filled" severity={type} onClose={() => onClose(id)}>
            {message}
          </Alert>
        </Snackbar>
      ))}
    </ToastContext.Provider>
  );
};

const useToast = () => useContext(ToastContext);

export default useToast;
