import { Button } from '@mui/material';

import { ActionsPopover } from 'components/popovers/actions-popover';

import { usePopover } from 'components/popovers/popover-wrapper/usePopover';

import { ReactComponent as LoginAsUserIcon } from 'assets/icons/login-as-user-icon.svg';
import { ReactComponent as EditUserIcon } from 'assets/icons/edit-icon.svg';

import { popperStyles } from 'components/popovers/actions-popover/styles';

export const UserTableActionsPopper = ({ handleEditUser, handleLoginAsUser }: any) => {
  const { anchorEl, handleOpen, handleClose, open } = usePopover();

  const handleEdit = () => {
    handleEditUser();
    handleClose();
  };

  const handleLogin = () => {
    handleLoginAsUser();
    handleClose();
  };

  return (
    <ActionsPopover
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
      anchorEl={anchorEl}
    >
      <Button sx={popperStyles.actionBtn} startIcon={<EditUserIcon />} onClick={handleEdit}>
        edit user
      </Button>
      <Button sx={popperStyles.actionBtn} startIcon={<LoginAsUserIcon />} onClick={handleLogin}>
        login as user
      </Button>
    </ActionsPopover>
  );
};
