export const flexAlignCenter = {
  display: 'flex',
  alignItems: 'center',
};

export const outlinedButtonStyles = {
  width: '70px',
  background: '#FFFFFF',
  borderRadius: '8px',
  border: '1px solid #4D6EF5',
  padding: '8px 16px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '146%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: '#4D6EF5',
  transition: '300ms',
  textTransform: 'none',
};

export const filledButtonStyles = {
  width: '70px',
  background: '#4D6EF5',
  borderRadius: '8px',
  border: '1px solid #4D6EF5',
  padding: '8px 16px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '146%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: '#FFFFFF',
  transition: '300ms',
  textTransform: 'none',

  '&:hover': { color: '#4D6EF5' },
};

export const resetButtonStyles = {
  background: 'transparent',
  border: 'none',
  padding: '0',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '146%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: '#23252F',
  transition: '300ms',
  textTransform: 'none',
  minWidth: 'auto',
};
