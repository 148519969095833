import { FormControl, FormHelperText, TextField } from '@mui/material';

import { StyledLabel } from 'components/common/styled-label';

import { autocompleteTextFieldStyles } from './styles';

interface IProps {
  error?: boolean;
  name: string;
  fullWidth?: boolean;
  label?: string;
  helperText?: string;
  [x: string]: any;
}

export const AutocompleteTextField = ({ label, fullWidth, helperText, name, ...props }: IProps) => {
  return (
    <FormControl fullWidth={fullWidth}>
      {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}

      <TextField sx={autocompleteTextFieldStyles.input} {...props} />

      {helperText && (
        <FormHelperText sx={autocompleteTextFieldStyles.helperText}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
