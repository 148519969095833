const text = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '146%',
  textAlign: 'center',
};

export const iconMessageStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    marginBottom: '24px',
  },
  text1: {
    marginBottom: '12px',
    ...text,
    fontSize: '18px',
  },
  text2: {
    ...text,
  },
};
