import { useCallback, useEffect, useState } from 'react';

import { Grid, InputAdornment, OutlinedInput, debounce } from '@mui/material';

import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';

import { tableSearchStyles } from './styles';

interface IProps {
  getDebouncedSearchValue: (value: string) => void;
  defaultSearchValue: string;
  maxWidth?: string;
  inputRef?: any;
  [x: string]: any;
}

export const Search = ({
  getDebouncedSearchValue,
  defaultSearchValue,
  maxWidth,
  ...rest
}: IProps) => {
  const [searchValue, setSearchValue] = useState(defaultSearchValue || '');

  useEffect(() => {
    setSearchValue(defaultSearchValue || '');
  }, [defaultSearchValue]);

  const debounceFn = useCallback(debounce(getDebouncedSearchValue, 500), [getDebouncedSearchValue]);

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
    debounceFn(event.target.value);
  };

  return (
    <Grid sx={tableSearchStyles.searchInputContainer} maxWidth={maxWidth}>
      <OutlinedInput
        {...rest}
        fullWidth
        placeholder="Search users"
        startAdornment={
          <InputAdornment position="start" sx={tableSearchStyles.inputAdornment}>
            <Grid item sx={tableSearchStyles.inputAdornmentContainer}>
              <SearchIcon />
            </Grid>
          </InputAdornment>
        }
        sx={tableSearchStyles.searchInput}
        value={searchValue}
        onChange={handleSearchChange}
      />
    </Grid>
  );
};
