import { Collapse, Grid } from '@mui/material';
import { WithLabelCustomSwitch } from '../with-label-custom-switch';

export const WithAccordionSwitch = ({ isCollapsed, children, ...rest }: any) => {
  return (
    <div>
      <WithLabelCustomSwitch {...rest} />
      {!!isCollapsed && (
        <Collapse in={isCollapsed} timeout={500}>
          <Grid>{children}</Grid>
        </Collapse>
      )}
    </div>
  );
};
