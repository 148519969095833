import { Box } from '@mui/material';

import { PlanTypeEnum } from 'pages/plans/utils';

import { planTypeStyles } from './styles';

interface IProps {
  type: PlanTypeEnum;
}

const statusMappings = {
  [PlanTypeEnum.BASE_PLAN]: {
    style: planTypeStyles.base,
    text: 'Base',
  },
  [PlanTypeEnum.ENTERPRICE]: {
    style: planTypeStyles.enterprice,
    text: 'Enterprice',
  },
  [PlanTypeEnum.CUSTOM_PLAN]: {
    style: planTypeStyles.custom,
    text: 'Custom',
  },
  [PlanTypeEnum.STORAGE_PLAN]: {
    style: planTypeStyles.storage,
    text: 'Storage',
  },
};

export const PlanType = ({ type }: IProps) => {
  if (!statusMappings.hasOwnProperty(type)) {
    console.error(`Invalid status: ${type}`);
    return null;
  }

  const { style = {}, text = '' } = statusMappings[type] || {};

  return (
    <Box component="span" sx={[planTypeStyles.box, style]}>
      {text}
    </Box>
  );
};
