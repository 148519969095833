export const styles = {
  container: {
    backgroundColor: 'white',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '8px 14px',
    border: '1px solid #EDEFF7',
    borderRadius: '8px',
    minHeight: '56px',
  },
  label: {
    fontFamily: 'Inter, sans-serif ',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '500',
    color: '#23252F',
  },
};
