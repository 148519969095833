import { useCallback, useMemo } from 'react';

import { TableCell, TableRow } from '@mui/material';

import { CustomTable } from 'components/table/custom-table';
import { PlanType } from 'components/plan-type';
import { PlanTableActionsPopper } from './components';

import { mockPlanTableData } from 'pages/plans/utils';

import { planTableStyles } from './styles';

export const PlanTable = () => {
  const headerCells = useMemo(
    () => [
      'Plan Type',
      'Plan Name',
      '# of Projects',
      '# of Users',
      'Monthly Amount',
      'Annual Amount',
      ' ',
    ],
    []
  );

  const PlanRow = useCallback(
    (plan: any) => (
      <TableRow sx={planTableStyles.tableRow} key={plan.id}>
        <TableCell component="th" scope="row">
          <PlanType type={plan.type} />
        </TableCell>
        <TableCell align="left">{plan.name}</TableCell>
        <TableCell align="left">{plan.ofProjects}</TableCell>
        <TableCell align="left">{plan.ofUsers}</TableCell>
        <TableCell align="left">{plan.mohtlyAmount}</TableCell>
        <TableCell align="left">{plan.annualAmount}</TableCell>
        <TableCell align="center" sx={{ width: '48px', boxSizing: 'border-box', padding: 0 }}>
          <PlanTableActionsPopper />
        </TableCell>
      </TableRow>
    ),
    []
  );

  const mockMeta = useMemo(
    () => ({ currentPage: 1, itemCount: 1, itemsPerPage: 10, totalItems: 1, totalPages: 1 }),
    []
  );

  return (
    <CustomTable
      isLoading={false}
      headerCells={headerCells}
      tableMeta={mockMeta}
      tableData={mockPlanTableData}
      renderRow={PlanRow}
    />
  );
};
