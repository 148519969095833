// mui components
import {
  FormControl,
  FormHelperText,
  OutlinedInput as MuiOutlinedInput,
  outlinedInputClasses,
  OutlinedInputProps,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import { FormControlProps } from '@mui/material/FormControl/FormControl';

export interface IOutlinedInputProps extends OutlinedInputProps {
  name?: string;
  label?: string;
  width?: string;
  required?: boolean;
  helperText?: string;
  placeholder?: string;
  withoutHelperText?: boolean;
  sxControl?: FormControlProps['sx'];
  InputProps?: any;
  className?: string;
  optional?: boolean;
  smallsize?: string;
  inputProps?: any;
}

const StyledOutlinedInputNewUI = styled((props: IOutlinedInputProps) => (
  <MuiOutlinedInput {...props} />
))(({ theme, readOnly, width, multiline, smallsize }) => ({
  pointerEvents: readOnly ? 'none' : 'auto',
  [`&.${outlinedInputClasses.root}`]: {
    height: multiline ? '100%' : '48px',
    width: width || '100%',
    backgroundColor: readOnly ? '#FAFBFC' : '#fff',
    borderRadius: '12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:before': {
      border: 'unset !important',
    },
    '&:after': {
      border: 'unset !important',
    },
    '&:hover': {
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        border: '1px solid #EDEFF7',
      },
    },
    '&.Mui-focused': {
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#EDEFF7',
        borderWidth: '1px',
      },
    },
    '&.Mui-error': {
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#EDEFF7',
      },
    },
    '&.Mui-disabled': {
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#EDEFF7',
      },
    },
  },

  [`& .${outlinedInputClasses.input}`]: {
    fontSize: '14px',
    lineHeight: '146%',
    fontWeight: 400,
    color: '#23252F',
    fontFamily: 'Inter, sans-serif !important',
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: '1px solid #EDEFF7',
    borderRadius: smallsize ? '6px !important' : '12px',
    '&.Mui-error': {
      border: '1px solid #EDEFF7',
    },
  },
  '& .MuiSelect-icon': {
    top: 'calc(50% - 11.5px)',
    right: '14px',
    transition: 'all 0.3s ease-in-out',
  },
  '& input::placeholder': {
    fontSize: '14px',
    lineHeight: '146%',
    color: '#8C8F9D',
    opacity: 1,
    fontFamily: 'Inter, sans-serif !important',
  },
}));

export const StyledLabel = styled('label')(() => ({
  width: '100%',
  marginBottom: '6px',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,
  color: '#1D2939',
}));

export const StyledLabelNewUI = styled('label')(() => ({
  width: '100%',
  marginBottom: '8px',
  fontSize: '14px',
  lineHeight: '146%',
  fontWeight: 400,
  color: '#23252F',
  fontFamily: 'Inter, sans-serif !important',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledAsterisk = styled('span')(() => ({
  marginLeft: '3px',
  color: 'red',
}));

export const CustomOutlinedInput: React.FC<IOutlinedInputProps> = ({
  name,
  label,
  placeholder,
  required,
  helperText,
  fullWidth,
  readOnly,
  withoutHelperText,
  sxControl,
  className,
  optional,
  ...props
}) => {
  return (
    <FormControl fullWidth={fullWidth} sx={sxControl}>
      {label && (
        <StyledLabelNewUI htmlFor={name}>
          {label}

          {optional && (
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '150%',
                color: '#6E707A',
                fontFamily: 'Inter, sans-serif !important',
              }}
            >
              optional
            </Typography>
          )}
        </StyledLabelNewUI>
      )}
      <StyledOutlinedInputNewUI
        id={name}
        optional={optional}
        placeholder={placeholder}
        readOnly={readOnly}
        autoComplete="off"
        {...props}
      />
      {!withoutHelperText && helperText && helperText != ' ' && (
        <FormHelperText sx={{ margin: '3px 0', color: 'red' }}>{helperText || ' '}</FormHelperText>
      )}
    </FormControl>
  );
};
