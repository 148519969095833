import { Grid, MenuItem, Select, SelectChangeEvent, SelectProps, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { CustomOutlinedInput } from '../custom-outlined-input';

import { FieldError } from 'react-hook-form';

import { ICommonSelectValues } from 'utils/types';

import { ReactComponent as CheckIcon } from 'assets/icons/Check_icon.svg';
import { ReactComponent as PaginationArrowDownIcon } from 'assets/icons/Chevron-down_New_Icon.svg';

import { customizedSelectStyles } from './styles';

export interface IProps extends Omit<SelectProps, 'error'> {
  options: ICommonSelectValues[];
  optionStyles?: { [key: string]: string };
  required?: boolean;
  withError?: boolean;
  withoutHelperText?: boolean;
  maxWidth?: string;
  error?: FieldError;
  label?: string;
  value: string;
  onChange: any;
  additionalOnChange?: any;
}

export const CustomSelect = ({
  value,
  options,
  error,
  onChange,
  required,
  withError,
  label,
  name,
  placeholder,
  additionalOnChange,
  ...props
}: IProps) => {
  const menuProps = { sx: customizedSelectStyles.menu };

  const handleChange = (event: SelectChangeEvent<any>, child: React.ReactNode) => {
    onChange(event, child);
    !!additionalOnChange && additionalOnChange(event.target.value);
  };

  const getSelectedLabels = (selectedValues: any) => {
    return selectedValues.map(
      (selectedValue: any) => options.find((option) => option?.value === selectedValue)?.label
    );
  };

  const renderPlaceholder = () => {
    return (
      placeholder && (
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
      )
    );
  };

  const renderMenuItem = () => {
    return options?.map((option: any) => {
      return (
        <MenuItem
          key={option?.id}
          value={option?.value}
          disabled={option?.disabled}
          sx={customizedSelectStyles.menuItem}
        >
          <Grid sx={customizedSelectStyles.menuItemContent}>{option?.label}</Grid>
          <Box component="span" className="checkIcon">
            <CheckIcon />
          </Box>
        </MenuItem>
      );
    });
  };

  const renderValue = (selected: any) => {
    if (props.multiple) {
      if (selected.length === 0) {
        return <span style={customizedSelectStyles.placeholder}>{placeholder}</span>;
      }
      return (
        <Grid style={customizedSelectStyles.inputTextContainer}>
          <Typography sx={customizedSelectStyles.inputText} noWrap>
            {getSelectedLabels(selected).join(', ')}
          </Typography>
        </Grid>
      );
    } else {
      const selectedOption = options.find((opt) => opt.value === selected);
      return selectedOption ? (
        selectedOption.label
      ) : (
        <span style={customizedSelectStyles.placeholder}>{placeholder}</span>
      );
    }
  };

  return (
    <Select
      {...props}
      MenuProps={menuProps}
      sx={customizedSelectStyles.select}
      renderValue={renderValue}
      name={name}
      displayEmpty={true}
      value={value}
      defaultValue={value}
      onChange={handleChange}
      IconComponent={PaginationArrowDownIcon}
      input={
        <CustomOutlinedInput
          fullWidth
          name={name}
          label={label}
          error={!!error}
          required={!!required}
          helperText={withError ? error?.message || ' ' : ' '}
          withoutHelperText={!withError}
          sx={customizedSelectStyles.input}
        />
      }
    >
      {renderPlaceholder()}
      {renderMenuItem()}
    </Select>
  );
};
