import { flexAlignCenter } from 'globalStyles';

const typography = {
  fontFamily: 'Inter, sans-serif',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
};

const borderStyles = '1px solid #E9EAF2';

export const paginationStyles = {
  container: {
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '20px', // should be removed
    border: borderStyles,
    borderRadius: '10px',
  },
  itemsPerPageText: {
    color: '#23252F',
    padding: '14px 17px',
    ...typography,
  },
  ofPageContainer: {
    ...flexAlignCenter,
    borderLeft: borderStyles,
    borderRight: borderStyles,
  },
  leftController: {
    borderRight: borderStyles,
  },
  itemsOfPagesText: {
    padding: '14px 17px',
    ...typography,
  },
  itemsOfText: {
    padding: '14px 16px 14px 0px',
    ...typography,
  },
  paginationSelectContainer: {
    padding: '8px 16px',
  },
  paginationSelectLeft: {
    borderLeft: borderStyles,
    borderRight: borderStyles,
  },
  controllersContainer: {
    ...flexAlignCenter,
  },
  controller: {
    padding: '16px 19px',
    borderRadius: 'unset',
  },
};
