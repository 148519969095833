export const headerStyles = {
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '73px',
    padding: '0 24px',
    backgroundColor: '#fff',
  },

  logoContainer: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '6px 20px 6px 8px',

    '& a': {
      display: 'flex',
    },
    '& svg': {
      height: '36px',
    },
    '& img': {
      height: '50px',
      objectFit: 'contain',
    },
  },

  popperButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      cursor: 'pointer',
    },
  },

  divider: {
    background: '#F3F4F9',
    opacity: '0.3',
    height: '48px',
  },
};
