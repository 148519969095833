export const tagStyles = {
  container: {
    userSelect: 'none',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    maxWidth: '265px',
    padding: '11px 21px',
    backgroundColor: '#fff',
    border: '1px solid #EDEFF7',
    borderRadius: '12px',

    '&:hover': {
      backgroundColor: '#F2F5FF',
      border: '1px solid #4D6EF5',
      transition: 'background-color 0.3s ease-out, border 0.3s ease-out, color 0.3s ease-out',
      '& .tag-text': {
        color: '#4D6EF5',
      },
    },
  },
  text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: '#23252F',
  },
  icon: {
    cursor: 'pointer',
  },
};
