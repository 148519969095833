export const selectPopoverStyles = {
  button: {
    width: '93px',
    height: '36px',
    background: '#4d6ef5',
    border: '1px solid #4d6ef5',
    borderRadius: '8px',
    justifyContent: 'center',
    transition: '300ms',
    gap: '8px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    display: 'flex',
    alignItems: 'center',
    color: '#ffffff',
    cursor: 'pointer',
    textTransform: 'none',
    padding: '5px 10px',

    '&:hover': {
      color: '#4d6ef5',
    },

    '&:active': {
      filter: 'none',
    },

    '&:focus': { filter: 'none' },

    '& span': {
      marginRight: '0',
      marginLeft: '0',
    },

    "&[data-size='large']": {
      height: '36px',
    },

    "&[data-size='small']": {
      height: '28px',
      padding: '5px 6px',
    },
  },
  listItem: {
    cursor: 'pointer',
    minWidth: '174px',
    '&:hover': {
      backgroundColor: '#F2F5FF',
      transition: 'background-color 0.2s ease-out',
    },
  },
  text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    color: '#23252F',
  },
};
