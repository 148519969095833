import { memo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
// components
import { CustomOutlinedInput } from 'components/inputs/custom-outlined-input';

interface OwnProps {
  name: string;
  label?: string;
  placeholder?: string;
  withError?: boolean;
  [x: string]: any;
}

export const OutlinedInputController: React.FC<OwnProps> = memo(
  ({ name = '', defaultValue = '', withError, ...props }) => {
    const { control } = useFormContext();

    return (
      <Controller
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
          return (
            <CustomOutlinedInput
              {...props}
              name={name}
              value={value}
              error={!!error}
              onBlur={onBlur}
              onChange={onChange}
              helperText={withError ? error?.message || ' ' : ' '}
            />
          );
        }}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    );
  }
);
