import React, { memo } from 'react';
import { Snackbar, Alert } from '@mui/material';

interface OwnProps {
  isOpen: boolean;
  message: string;
  handleClose: () => void;
}

const ErrorSnackbar: React.FC<OwnProps> = ({ isOpen, message, handleClose }) => (
  <div>
    <Snackbar
      open={isOpen}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        severity="error"
        onClose={handleClose}
        sx={{
          color: '#fff',
          backgroundColor: 'rgb(211, 47, 47)',
          '& svg': {
            fill: '#fff',
          },
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  </div>
);

export default memo(ErrorSnackbar);
