import { useEffect, useState } from 'react';
// custom hooks
import { useWindowWidth } from 'hooks/useWindowWidth';
// routing
import { useLocation } from 'react-router-dom';
// mui components
import { Drawer, Grid, IconButton } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { sidebarStyles } from './styles';
// components
import { SidebarLinkTab } from './components/sidebar-link-tab';
//icons
import { ReactComponent as UserIcon } from 'assets/icons/users-icon.svg';
import { ReactComponent as UserDarkIcon } from 'assets/icons/users-icon-dark.svg';
import { ReactComponent as PlansIcon } from 'assets/icons/plans-icon.svg';
import { ReactComponent as PlansDarkIcon } from 'assets/icons/plans-icon-dark.svg';
import { ReactComponent as TemplatesIcon } from 'assets/icons/templates-icon.svg';
import { ReactComponent as TemplatesDarkIcon } from 'assets/icons/templates-icon-dark.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ChevronRightActiveIcon } from 'assets/icons/chevron-right-active.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const SMALL_WINDOW_WIDTH = 768;

export const Sidebar = () => {
  const windowWidth = useWindowWidth();
  const [isVisible, setIsVisible] = useState(false);

  const { pathname } = useLocation();
  const path = pathname.replace('/', '');
  const isSmall = windowWidth <= SMALL_WINDOW_WIDTH;

  const tabData = [
    createTabData('users', 'Users', '/users', UserIcon, UserDarkIcon),
    createTabData('plans', 'Plans', '/plans', PlansIcon, PlansDarkIcon),
    createTabData('templates', 'Templates', '/templates', TemplatesIcon, TemplatesDarkIcon),
  ];

  useEffect(() => {
    if (isVisible) setIsVisible(false);
  }, [windowWidth]);

  const handleToggleSidebar = () => {
    setIsVisible((prev) => !prev);
  };

  function createTabData(
    value: string,
    label: string,
    href: string,
    activeIcon: any,
    inactiveIcon: any
  ) {
    return {
      value,
      label,
      href,
      icon: renderIcon(path === value ? activeIcon : inactiveIcon),
      chevron: renderChevron(path === value),
    };
  }

  function renderIcon(IconComponent: any) {
    return <IconComponent className="svg-icon" />;
  }

  function renderChevron(isActive: boolean) {
    return isActive ? <ChevronRightActiveIcon /> : <ChevronRightIcon />;
  }

  const TabListComponent = (
    <TabList
      orientation="vertical"
      variant="scrollable"
      aria-label="Users Tabs"
      sx={sidebarStyles.tabList}
      TabIndicatorProps={{
        style: { display: 'none' },
      }}
    >
      {tabData.map((tab) => (
        <SidebarLinkTab
          key={tab.value}
          href={tab.href}
          label={tab.label}
          value={tab.value}
          icon={tab.icon}
          chevron={tab.chevron}
        />
      ))}
    </TabList>
  );

  const DrawerComponent = (
    <Drawer transitionDuration={500} onClose={handleToggleSidebar} anchor="left" open={isVisible}>
      <Grid onClick={handleToggleSidebar} sx={sidebarStyles.closeButton}>
        <CloseIcon />
      </Grid>
      <Grid container sx={sidebarStyles.container}>
        <TabContext value={path || 'users'}>{TabListComponent}</TabContext>
      </Grid>
    </Drawer>
  );

  const SidebarIconButton = (
    <IconButton onClick={handleToggleSidebar} sx={sidebarStyles.iconButton}>
      <ChevronRightActiveIcon />
    </IconButton>
  );

  return isSmall ? (
    <>
      {DrawerComponent}
      {SidebarIconButton}
    </>
  ) : (
    <Grid container sx={sidebarStyles.container}>
      <TabContext value={path || 'users'}>{TabListComponent}</TabContext>
    </Grid>
  );
};
