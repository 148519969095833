// mui components
import { Grid, OutlinedInputProps, TextFieldProps } from '@mui/material';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// components
import { CustomOutlinedInput } from '../custom-outlined-input';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/chevron-right-v2.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/chevron-down-v2.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-icon.svg';

interface OwnProps {
  name: string;
  isStatic?: boolean;
  error?: boolean;
  placeholder?: string;
  label?: string;
  required?: boolean;
  helperText?: string;
  value?: string;
  fullWidth?: boolean;
  onChange: (...event: any[]) => void;
  additionalOnChange?: (...event: any[]) => void;
  [x: string]: any;
}

export const CustomDatePicker: React.FC<OwnProps> = ({
  name,
  label,
  error,
  required,
  placeholder,
  helperText,
  value,
  fullWidth,
  maxDate,
  minDate,
  disablePast,
  shouldDisableDate,
  onChange,
  additionalOnChange,
}) => {
  const dateAdapter = new AdapterDateFns();

  const validationValue = value ? new Date(value) : 0;
  const validationMinDate = minDate ? new Date(minDate) : 0;

  const datePickerValue = validationValue >= validationMinDate ? value : null;

  const handleChange = (date: Date | null) => {
    onChange(date);
    !!additionalOnChange && additionalOnChange(date);
  };

  const renderInput = ({ InputProps, ...inputProps }: TextFieldProps) => {
    return (
      <Grid item>
        <CustomOutlinedInput
          onKeyDown={(e) => e.preventDefault()}
          {...(inputProps as OutlinedInputProps)}
          inputProps={{
            ...inputProps.inputProps,
            value: value ? dateAdapter.format(new Date(value), 'keyboardDate') : '',
          }}
          name={name}
          label={label}
          error={error}
          required={required}
          fullWidth={fullWidth}
          placeholder={placeholder}
          helperText={helperText}
          endAdornment={InputProps?.endAdornment}
        />
      </Grid>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MUIDatePicker
        defaultCalendarMonth={minDate}
        shouldDisableDate={shouldDisableDate}
        disablePast={disablePast}
        maxDate={maxDate}
        minDate={minDate}
        value={datePickerValue}
        onChange={handleChange}
        renderInput={renderInput}
        components={{
          OpenPickerIcon: CalendarIcon,
          LeftArrowIcon: ArrowLeftIcon,
          RightArrowIcon: ArrowRightIcon,
          SwitchViewIcon: ArrowDownIcon,
        }}
      />
    </LocalizationProvider>
  );
};
