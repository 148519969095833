import { Grid, InputAdornment } from '@mui/material';

import { NumberFormatValues, NumericFormat } from 'react-number-format';

import { CustomOutlinedInput } from '../custom-outlined-input';

interface IProps {
  onChange: (value: string | number | undefined) => void;
  showStartAdornment?: boolean;
  showEndAdornment?: boolean;
  adornmentContent?: any;
  [x: string]: any;
}

export const NumbericFormatInput = ({
  onChange,
  showStartAdornment,
  showEndAdornment,
  adornmentContent,
  ...rest
}: IProps) => {
  const onValueChange = (values: NumberFormatValues) => {
    onChange(values.floatValue);
  };

  const renderAdornment = () => (
    <InputAdornment position={showStartAdornment ? 'start' : 'end'}>
      <Grid item>{adornmentContent}</Grid>
    </InputAdornment>
  );

  return (
    <NumericFormat
      {...rest}
      startAdornment={showStartAdornment ? renderAdornment() : undefined}
      endAdornment={showEndAdornment ? renderAdornment() : undefined}
      customInput={CustomOutlinedInput}
      onValueChange={onValueChange}
    />
  );
};
