import { useEffect, useState } from 'react';

import { debounce } from 'lodash';

export const useWindowWidth = (debounceTime = 300) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update window width
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    // Event listener with debounce
    const handleResize = debounce(updateWindowWidth, debounceTime);

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Initial width on mount
    updateWindowWidth();

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [debounceTime]);

  return windowWidth;
};
