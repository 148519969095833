import { Button, Grid } from '@mui/material';

import StyledForm from 'components/common/styled-form';
import { PopoverWrapper } from 'components/popovers/popover-wrapper';

import { filtersPopperStyles } from './styles';
import { filledButtonStyles, outlinedButtonStyles, resetButtonStyles } from 'globalStyles';

export const PopperButton = ({
  open,
  anchorEl,
  handleOpen,
  handleClose,
  buttonIcon,
  buttonText,
  children,
  handleCancel,
  handleClear,
  handleSubmit,
}: any) => {
  const renderFooter = () => {
    return (
      <Grid sx={filtersPopperStyles.buttonsContainer}>
        <Button sx={resetButtonStyles} onClick={handleClear}>
          Clear
        </Button>
        <Grid sx={filtersPopperStyles.mainActionButtons}>
          <Button sx={outlinedButtonStyles} onClick={handleCancel}>
            Cancel
          </Button>
          <Button sx={filledButtonStyles} type="submit">
            Apply
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid>
      <Button
        variant="outlined"
        onClick={handleOpen}
        startIcon={buttonIcon}
        sx={filtersPopperStyles.button}
      >
        {buttonText}
      </Button>
      <PopoverWrapper handleClose={handleClose} anchorEl={anchorEl} open={open}>
        <Grid sx={filtersPopperStyles.paperContentContainer}>
          <StyledForm onSubmit={handleSubmit}>
            {children}
            {renderFooter()}
          </StyledForm>
        </Grid>
      </PopoverWrapper>
    </Grid>
  );
};
