import { Grid, Typography } from '@mui/material';

import { ReactComponent as CrossIcon } from 'assets/icons/cross-icon.svg';

import { tagStyles } from './styles';

interface IProps {
  text: string;
  onDelete: any;
}

export const Tag = ({ text, onDelete }: IProps) => {
  return (
    <Grid sx={tagStyles.container}>
      <Grid style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}>
        <Typography className="tag-text" sx={tagStyles.text} noWrap>
          {text}
        </Typography>
      </Grid>
      <Grid sx={tagStyles.icon} onClick={onDelete}>
        <CrossIcon />
      </Grid>
    </Grid>
  );
};
