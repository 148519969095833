import { PaginationSelect } from './components/pagination-select';

import { Grid, IconButton, SelectChangeEvent, Typography } from '@mui/material';

import { ReactComponent as PaginationArrowLeftIcon } from 'assets/icons/pagination-arrow-left.svg';
import { ReactComponent as PaginationArrowRightIcon } from 'assets/icons/pagination-arrow-right.svg';

import { flexAlignCenter } from 'globalStyles';
import { paginationStyles } from './styles';

interface IProps {
  rowsPerPageOptions: number[];
  rowsPerPage: number;
  count: number;
  page: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  onRowsPerPageChange: (event: SelectChangeEvent) => void;
  onSelectPage: (event: SelectChangeEvent) => void;
}

export const CustomizedTablePagination = ({
  rowsPerPageOptions,
  rowsPerPage,
  count,
  page,
  totalPages,
  onPageChange,
  onRowsPerPageChange,
  onSelectPage,
}: IProps) => {
  const itemsRangeStart: number = page * rowsPerPage + 1;
  const itemsRangeEnd: number = Math.min((page + 1) * rowsPerPage, count);

  const isBackControlDisabled = page - 1 < 0;
  const isNextControlDisabled = page + 1 === totalPages;

  const pageArray = Array.from(Array(totalPages), (_, index) => index + 1);

  return (
    <Grid sx={paginationStyles.container}>
      {/* Left side of pagination */}
      <Grid sx={[flexAlignCenter, { flexShrink: '0' }]}>
        <Typography sx={paginationStyles.itemsPerPageText}>Items per page:</Typography>
        <Grid
          sx={[paginationStyles.paginationSelectContainer, paginationStyles.paginationSelectLeft]}
        >
          <PaginationSelect
            totalItemsCount={count}
            onChange={onRowsPerPageChange}
            values={rowsPerPageOptions}
            value={rowsPerPage}
          />
        </Grid>
        <Typography sx={paginationStyles.itemsOfPagesText}>
          {itemsRangeStart}-{itemsRangeEnd} of {count} items
        </Typography>
      </Grid>

      {/* Right side of pagination */}
      <Grid sx={[flexAlignCenter, { flexShrink: '0' }]}>
        <Grid sx={paginationStyles.ofPageContainer}>
          <Grid sx={paginationStyles.paginationSelectContainer}>
            <PaginationSelect onChange={onSelectPage} values={pageArray} value={page + 1} />
          </Grid>
          <Typography sx={paginationStyles.itemsOfText}>of {totalPages} pages</Typography>
        </Grid>

        <Grid sx={paginationStyles.controllersContainer}>
          <IconButton
            disabled={isBackControlDisabled}
            onClick={() => onPageChange(page - 1)}
            sx={[paginationStyles.controller, paginationStyles.leftController]}
            aria-label="go back"
          >
            <PaginationArrowLeftIcon />
          </IconButton>

          <IconButton
            disabled={isNextControlDisabled}
            onClick={() => onPageChange(page + 1)}
            sx={paginationStyles.controller}
            aria-label="go next"
          >
            <PaginationArrowRightIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
