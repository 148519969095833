import { PlanStatus } from 'components/plan-status';

import { PlanStatusEnum } from 'pages/users/entities';

export const plansStatusData = [
  {
    value: PlanStatusEnum.INACTIVE,
    label: <PlanStatus status={PlanStatusEnum.INACTIVE} />,
    id: 1,
  },
  {
    value: PlanStatusEnum.TRIALING,
    label: <PlanStatus status={PlanStatusEnum.TRIALING} />,
    id: 2,
  },
  {
    value: PlanStatusEnum.ACTIVE,
    label: <PlanStatus status={PlanStatusEnum.ACTIVE} />,
    id: 3,
  },
];

export const cancelledTrialPlansStatusData = [
  {
    value: PlanStatusEnum.INACTIVE,
    label: <PlanStatus status={PlanStatusEnum.INACTIVE} />,
    id: 1,
  },
  {
    value: PlanStatusEnum.TRIALING,
    label: <PlanStatus status={PlanStatusEnum.TRIALING} />,
    id: 2,
  },
];

export const cancelledNotTrialPlansStatusData = [
  {
    value: PlanStatusEnum.INACTIVE,
    label: <PlanStatus status={PlanStatusEnum.INACTIVE} />,
    id: 1,
  },
  {
    value: PlanStatusEnum.ACTIVE,
    label: <PlanStatus status={PlanStatusEnum.ACTIVE} />,
    id: 2,
  },
];

export const datePickerDefaultState = {
  name: '',
  label: '',
  key: '',
  defaultCalendarMonth: undefined,
  minDate: null,
  isVisible: false,
};

export const planStatusDefaultState = {
  data: plansStatusData,
  disabled: false,
};
