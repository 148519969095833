export const helpMenuStyles = {
  questionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    background: '#FFFFFF',
    border: '1px solid #F3F4F9',
    borderRadius: '8px',
    margin: '0 20px',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid rgb(243, 244, 249)',
      background: 'rgba(25, 118, 210, 0.04)',
      boxShadow: 'rgb(77, 110, 245) 0px 0px 2px',
    },
  },
};
