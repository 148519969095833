import React, { memo } from 'react';
// mui components
import {
  TextField,
  filledInputClasses,
  FilledTextFieldProps,
  OutlinedInputProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface OwnProps extends FilledTextFieldProps {
  separateLabel?: boolean;
}

const StyledTextField = styled(({ InputProps, ...props }: OwnProps) => (
  <TextField
    InputProps={{ ...InputProps, disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme, separateLabel }) => ({
  [`& .${filledInputClasses.input}`]: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'normal',
  },
  [`& .${filledInputClasses.root}`]: {
    border: `1px solid rgba(235, 238, 246, 0.42)`,
    borderRadius: '4px',
    backgroundColor: 'rgba(235, 238, 246, 0.42)',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:before': {
      border: 'unset !important',
    },
    '&:after': {
      border: 'unset !important',
    },
    '&.Mui-error': {
      border: '1px solid #E22716',
    },
    '& .MuiInputAdornment-root': {
      marginRight: '8px',
    },
  },
  '& label': {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#999999',
    display: separateLabel ? 'none' : 'block',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#000',
  },
}));

const BaseInput: React.FC<OwnProps> = (props) => {
  return <StyledTextField {...props} />;
};

export default memo(BaseInput);
