export const sidebarLayoutStyles = {
  container: {
    display: 'flex',
    gap: '19px',
    padding: '17px 0 31px 0',
    backgroundColor: '#FAFBFC',
  },
  outletContainer: {
    width: '100%',
    backgroundColor: '#FAFBFC',
    overflow: 'auto',
  },
  content: {
    background: '#FFF',
    border: '1px solid #EDEFF7',
  },
};
