export const userStatusStyles = {
  box: {
    display: 'inline-block',
    width: '87px',
    padding: '5px 0',
    textAlign: 'center',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif !important',
    fontSize: '10px',
    lineHeight: '14px',
  },
  canceled: {
    backgroundColor: '#FFD3D6',
    color: '#D12D36',
  },
  trialing: {
    backgroundColor: '#FFF2D8',
    color: '#E9A21A',
  },
  active: {
    backgroundColor: '#CAEFD5',
    color: '#379552',
  },
  expired: {
    backgroundColor: '#EDECEC',
    color: '#494949',
  },
  pending: {
    backgroundColor: '#D8D9FF',
    color: '#371AE9',
  },
  fullWidth: {
    width: '100%',
  },
};
