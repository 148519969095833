import { useCallback, useState } from 'react';

import { Autocomplete, Box, CircularProgress, Grid, Paper, debounce } from '@mui/material';

import { AutocompleteTextField } from './components/autocomplete-textfield';

import { ReactComponent as PaginationArrowDownIcon } from 'assets/icons/Chevron-down_New_Icon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/Check_icon.svg';

import { customAutocompleteStyles } from './styles';

export const CustomAutocomplete = ({
  value,
  onInputClose,
  helperText,
  defaultValue,
  label,
  options,
  isLoading,
  onChange,
  placeholder,
  getDebouncedSearchValue,
  renderOptionContent,
  getOptionLabel,
  isOptionEqualToValue,
  fullWidth,
  disableClearable,
  disableCloseOnSelect,
  multiple,
  renderSelectedTags,
  notFoundLabel = 'No Users',
}: any) => {
  const [searchValue, setSearchValue] = useState('');
  const debounceFn = useCallback(debounce(getDebouncedSearchValue, 500), [getDebouncedSearchValue]);

  const isWithTags = value && value?.length && !!renderSelectedTags;

  const handleSearchChange = (_: any, v: string, reason: any) => {
    if (reason === 'reset') return;
    setSearchValue(v);
    debounceFn(v);
  };

  const handleResetInput = () => {
    setSearchValue('');
    !!onInputClose && onInputClose();
  };

  const renderTags = () => null;

  const renderInput = (params: any) => {
    return (
      <AutocompleteTextField
        label={label}
        helperText={helperText}
        placeholder={placeholder}
        {...params}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'off',
        }}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {isLoading && searchValue ? <CircularProgress color="info" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    );
  };

  const renderOptions = (props: any, option: any) => {
    return (
      <Grid sx={customAutocompleteStyles.option} {...props} key={option.id}>
        <Grid sx={customAutocompleteStyles.optionContent}>{renderOptionContent(option)}</Grid>
        <Box component="span" className="checkIcon">
          <CheckIcon />
        </Box>
      </Grid>
    );
  };

  const renderAutocompletePaper = useCallback(
    (props: any) => {
      if (!options?.length) {
        return (
          <Paper sx={customAutocompleteStyles.autocompletePaper}>
            <Grid sx={customAutocompleteStyles.optionContent}>{notFoundLabel}</Grid>
          </Paper>
        );
      }

      return <Paper sx={customAutocompleteStyles.autocompletePaper} {...props} />;
    },
    [JSON.stringify(options)]
  );

  return (
    <>
      <Autocomplete
        autoComplete
        includeInputInList
        freeSolo
        inputValue={searchValue}
        noOptionsText="No Users"
        forcePopupIcon
        popupIcon={<PaginationArrowDownIcon />}
        multiple={multiple}
        fullWidth={fullWidth}
        defaultValue={defaultValue}
        disableClearable={disableClearable}
        disableCloseOnSelect={disableCloseOnSelect}
        options={options}
        value={value}
        onChange={onChange}
        filterOptions={(x) => x} // Needs to be given from a parent component as a prop
        onClose={handleResetInput}
        PaperComponent={renderAutocompletePaper}
        onInputChange={handleSearchChange}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        renderOption={renderOptions}
        renderTags={renderTags}
        renderInput={renderInput}
      />
      {isWithTags ? (
        <Grid sx={customAutocompleteStyles.tagsContainer}>{renderSelectedTags(value)}</Grid>
      ) : null}
    </>
  );
};
