import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import queryString from 'query-string';

import { isValidQueryValue } from './utils';

export const useQueries = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setQueries = (queries: any) => {
    const mergedQueries = { ...parsedQueries, ...queries };
    const filteredQueries = validateQueries(mergedQueries);
    const modifiedQueries = stringifyQueries(filteredQueries);

    if (modifiedQueries === stringifyQueries(parsedQueries)) return;

    setSearchParams(modifiedQueries);
  };

  const stringifyQueries = (queries: any) => {
    return queryString.stringify(queries, { sort: false, arrayFormat: 'bracket' });
  };

  const validateQueries = (queries: any) => {
    const filteredQueries: any = {};

    for (let key in queries) {
      const queryValue = queries[key];
      if (isValidQueryValue(queryValue)) filteredQueries[key] = queries[key];
    }

    return filteredQueries;
  };

  const parsedQueries = useMemo(() => {
    return queryString.parse(searchParams.toString(), { sort: false, arrayFormat: 'bracket' });
  }, [searchParams]);

  return { parsedQueries, setQueries };
};
