import { Button, List, ListItem, ListItemText } from '@mui/material';

import { PopoverWrapper } from 'components/popovers/popover-wrapper';

import { usePopover } from 'components/popovers/popover-wrapper/usePopover';

import { selectPopoverStyles } from './styles';

export const SelectPopover = ({ onSelect, items, buttonText = '' }: any) => {
  const { handleOpen, handleClose, anchorEl, open } = usePopover();

  const handleItemClick = (item: any) => {
    onSelect(item.value);
    handleClose();
  };

  return (
    <>
      <Button sx={selectPopoverStyles.button} onClick={handleOpen}>
        {buttonText}
      </Button>
      <PopoverWrapper anchorEl={anchorEl} handleClose={handleClose} open={open}>
        <List>
          {items.map((item: any) => (
            <ListItem
              sx={selectPopoverStyles.listItem}
              key={item.id}
              onClick={() => handleItemClick(item)}
            >
              <ListItemText sx={selectPopoverStyles.text} primary={item.label} />
            </ListItem>
          ))}
        </List>
      </PopoverWrapper>
    </>
  );
};
