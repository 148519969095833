export const isValidQueryValue = (value: any) => {
  return (
    (typeof value === 'string' && value.trim() !== '') ||
    (Array.isArray(value) && value.length > 0) ||
    typeof value === 'number'
  );
};

export const checkQueryInURL = (queries: any) => {
  const decodedURL = decodeURIComponent(window.location.search.substring(1));
  const params = new URLSearchParams(decodedURL);

  return Object.keys(queries).some((key) => {
    if (Array.isArray(queries[key])) {
      return params.getAll(`${key}[]`).length > 0;
    }
    return params.get(key) !== null;
  });
};

export const checkField = (fields: any) => {
  return Object.keys(fields).some((key: string) => isValidQueryValue(fields[key]));
};

export const resetFields = (formData: any) => {
  for (let key in formData) {
    if (typeof formData[key] === 'string' || typeof formData[key] === 'number') formData[key] = '';
    if (Array.isArray(formData[key])) formData[key] = [];
  }

  return formData;
};
