export const planDetailsStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  userPlanInfo: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    lineHeight: '28px',
    color: '#23252F',
  },
};
