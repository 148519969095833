export const tableSearchStyles = {
  searchInputContainer: {
    width: '100%',
    display: 'flex',
    justifyCOntent: 'center',
    alignItems: 'center',
    padding: '0',
  },

  inputAdornment: {
    margin: 0,
    '& div': {
      display: 'flex',
    },
  },

  inputAdornmentContainer: {
    marginRight: '12px',
  },

  searchInput: {
    height: '36px !important',
    border: '1px solid #EDEFF7',
    borderRadius: '10px !important',
    fontFamily: 'Inter, sans-serif ',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '146%',
    textAlign: 'start',
    color: '#23252F',

    '& fieldset': {
      border: 'none !important',
    },
  },
};
