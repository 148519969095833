import { capitalize } from 'lodash';

import { PaymentPlan } from 'utils/types';

export const plansAdapter = (plans: PaymentPlan[], valueToString?: boolean) => {
  if (!plans?.length) return [];

  const adaptedPlans = plans?.map((plan: PaymentPlan) => ({
    label: `${plan.name} (${capitalize(plan.recurring)})`,
    value: valueToString ? `${plan.id}` : plan.id,
    id: plan.id,
  }));
  return adaptedPlans;
};
