import { SelectProps } from '@mui/material';

import { CustomSelect } from 'components/inputs/custom-select';

import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { ICommonSelectValues } from 'utils/types';

export interface IProps extends Omit<SelectProps, 'error'> {
  options: ICommonSelectValues[];
  optionStyles?: { [key: string]: string };
  required?: boolean;
  withError?: boolean;
  withoutHelperText?: boolean;
  reserveValue?: any;
  maxWidth?: string;
  error?: FieldError;
  label?: string;
  name: any;
  additionalOnChange?: any;
}

export const SelectController = ({
  options,
  reserveValue,
  name,
  defaultValue,
  ...props
}: IProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const selectValue = reserveValue !== undefined ? value || reserveValue : value;
        return (
          <CustomSelect
            value={selectValue}
            options={options}
            error={error}
            onChange={onChange}
            {...props}
          />
        );
      }}
    />
  );
};
