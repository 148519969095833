import { capitalize } from 'lodash';

import { PaymentPlan } from 'utils/types';

export const planNamesAdapter = (plans: PaymentPlan[]) => {
  return (
    plans?.map((plan) => ({
      label: `${plan.name} (${capitalize(plan.recurring)})`,
      value: plan.id,
      id: plan.id,
    })) || []
  );
};

export function findPlanById(plans: PaymentPlan[], id: number) {
  return plans?.find((plan: PaymentPlan) => plan.id === id);
}
