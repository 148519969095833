import { Grid, Typography } from '@mui/material';

import { ReactComponent as LogoDarkIcon } from 'assets/icons/logo_dark.svg';
import { ReactComponent as ErrorPageIcon } from 'assets/icons/404.svg';

export const NotFound: React.FC = () => {
  return (
    <Grid
      sx={{
        height: 'calc(100vh - 73px)',
        background: '#F2F5FF',
      }}
    >
      <Grid
        sx={{
          paddingBottom: '0px',
          textAlign: 'center',
          '& svg': {
            width: '130px',
            height: '25px',
            textAlign: 'center',
            margin: '44px auto 0',
          },
        }}
      >
        <LogoDarkIcon />
      </Grid>

      <Grid
        container
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ margin: '158px auto' }}
      >
        <Typography sx={{ width: '630px', height: '260px' }}>
          <ErrorPageIcon />
        </Typography>

        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '400',
            padding: '80px 0 30px 0',
            fontFamily: 'Inter, sans-serif !important',
            color: '#23252F',
          }}
        >
          This page doesn’t exist
        </Typography>
      </Grid>
    </Grid>
  );
};
