export const customTableStyles = {
  tableContainer: {
    boxShadow: 'none',
    border: '1px solid #F3F4F9',
    borderRadius: '10px',
  },
  table: {
    minWidth: 650,
    border: 'none',
  },
  tableHeader: {
    '& .MuiTableCell-head': {
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      color: '#23252F',
      border: '1px solid #F3F4F9',
    },
  },
};
