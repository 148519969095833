import { Outlet } from 'react-router-dom';

import { Grid } from '@mui/material';

import { Sidebar } from 'components/sidebar';

import { sidebarLayoutStyles } from './styles';

export const SidebarLayout = () => {
  const { container, outletContainer, content } = sidebarLayoutStyles;

  return (
    <Grid sx={container}>
      <Sidebar />
      <Grid sx={outletContainer}>
        <Grid sx={content}>
          <Outlet />
        </Grid>
      </Grid>
    </Grid>
  );
};
