import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// mui components
import { Grid, Typography, Button } from '@mui/material';
// components
import ErrorSnackbar from 'components/error-snackbar';
import DialogModal from 'components/dialog-modal';
// controllers
import PasswordInputController from 'controllers/password-input-controller';
import BaseInputController from 'controllers/base-input-controller';
// custom hooks
import { useUserData } from 'hooks/useUserData';
import { useAuth } from 'hooks/useAuth';
// api
import api from 'utils/api';
// icons
import { ReactComponent as CompleteCheckIcon } from 'assets/icons/complete_check.svg';
import { ReactComponent as LogoDark } from 'assets/icons/logo_dark.svg';
// validation
import { SignInSchema } from './validation';

const SignIn: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const { updateUserData } = useUserData();

  const { handleAuth } = useAuth();

  const form = useForm({ resolver: yupResolver(SignInSchema) });

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleCloseError = useCallback(() => {
    setErrorMessage('');
  }, []);

  const onSubmit = useCallback(
    async (formValues: any) => {
      try {
        const userData = await api.auth.signIn({ data: formValues });

        localStorage.setItem('userId', userData.id);

        await handleAuth();

        updateUserData({ isAuthorized: true });

        navigate('/users');
      } catch (err: any) {
        if (err.status === 401) {
          setErrorMessage('Email or password is incorrect, please try again');
        } else if (err.data.message?.[0]?.message === '"email" must be a valid email') {
          setErrorMessage('Please use valid email');
        } else {
          setErrorMessage('Something went wrong, please try again');
        }
      }
    },
    [updateUserData, handleAuth, navigate]
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: '100vh', height: '100%', backgroundColor: '#EAEFFB' }}
        >
          <Grid container flexDirection="column" alignItems="center">
            <Grid
              container
              flexDirection="column"
              sx={{
                maxWidth: '600px',
                margin: '100px 0',
                padding: '30px 24px 40px 24px',
                backgroundColor: '#fff',
                borderRadius: '14px',
              }}
            >
              <Grid
                container
                sx={{
                  paddingBottom: '16px',
                  borderBottom: '2px solid rgba(235, 238, 246, 0.42)',
                  '& svg': {
                    width: '130px',
                    height: '25px',
                  },
                }}
              >
                <LogoDark />
              </Grid>
              <Typography
                sx={{
                  margin: '30px 0 26px',
                  fontSize: '20px',
                  lineHeight: '24px',
                  fontWeight: 'bold',
                  color: '#353535',
                }}
              >
                Login to your account
              </Typography>
              <BaseInputController name="email" type="email" label="Email Address" />
              <PasswordInputController name="password" label="Password" />
              <Button
                type="submit"
                variant="contained"
                sx={{
                  height: '50px',
                  margin: '20px 0 30px',
                  borderRadius: '30px',
                  textTransform: 'unset',
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
              >
                Login to your account
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <DialogModal open={openModal} handleClose={handleCloseModal}>
        <Grid
          container
          flexDirection="column"
          alignItems="center"
          sx={{
            '& svg': {
              height: '100px',
              width: '100px',
              marginTop: '20px',
            },
          }}
        >
          <CompleteCheckIcon />
          <Typography
            sx={{
              margin: '20px 0 30px',
              fontSize: '24px',
              lineHeight: '30px',
              fontWeight: 'bold',
              color: '#0967D2',
            }}
          >
            Thank you, email verified
          </Typography>
        </Grid>
      </DialogModal>
      <ErrorSnackbar
        isOpen={!!errorMessage}
        message={errorMessage}
        handleClose={handleCloseError}
      />
    </FormProvider>
  );
};

export default SignIn;
