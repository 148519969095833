import { styled } from '@mui/system';
import { MenuItem } from '@mui/material';

export const StyledProfileMenuItem = styled(MenuItem)(() => ({
  width: '100%',
  padding: '10px 16px',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 400,
  color: '#667085',
  '& a': {
    display: 'flex',
    alignItems: 'center',
    color: '#535F77',
    textDecoration: 'unset',
  },
  '& svg': {
    width: '16px',
    height: '16px',
    marginRight: '12px',
  },
}));
