import ReactDOM from 'react-dom';
// routing
import { BrowserRouter } from 'react-router-dom';
// root page
import App from './pages/app';
// redux
import { store } from 'redux/store';
import { Provider } from 'react-redux';
// providers
import { ToastContextProvider } from 'hooks/useToast';
import { UserDataProvider } from 'hooks/useUserData';
// utils
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
// styles
import './index.css';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <UserDataProvider>
        <ToastContextProvider>
          <App />
        </ToastContextProvider>
      </UserDataProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
