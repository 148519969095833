import { useEffect } from 'react';

import { Grid } from '@mui/material';

import { FormProvider, useForm } from 'react-hook-form';

import { SelectController } from 'controllers/select-controller';

import { PopperButton } from './components/popper-button';

import { usePopover } from 'components/popovers/popover-wrapper/usePopover';

import { checkQueryInURL, resetFields } from 'hooks/useQueries/utils';

import { dropdownButtonStyles } from './styles';

interface IProps {
  defaultValues: any;
  fields: any;
  icon: any;
  buttonText: string;
  getValues: (formState: any) => void;
  onClear: any;
}

export const DropdownButton = ({
  defaultValues,
  fields,
  icon,
  buttonText,
  getValues,
  onClear,
}: IProps) => {
  const formMethods = useForm({ defaultValues });

  const { handleOpen, handleClose, open, anchorEl } = usePopover();

  const handleApply = (formState: any) => {
    if (JSON.stringify(defaultValues) !== JSON.stringify(formMethods.getValues())) {
      getValues(formState);
    }

    handleClose();
  };

  useEffect(() => formMethods.reset(defaultValues), [JSON.stringify(defaultValues)]);

  const handleClear = () => {
    const formData = formMethods.getValues();
    const isQueriesExist = checkQueryInURL(formData);

    if (!isQueriesExist) return handleCloseWithReset();

    onClear(resetFields(formData));
    handleCloseWithReset();
  };

  const handleCloseWithReset = () => {
    formMethods.reset(defaultValues);
    handleClose();
  };

  return (
    <PopperButton
      anchorEl={anchorEl}
      open={open}
      buttonText={buttonText}
      buttonIcon={icon}
      handleOpen={handleOpen}
      handleSubmit={formMethods.handleSubmit(handleApply)}
      handleCancel={handleCloseWithReset}
      handleClose={handleCloseWithReset}
      handleClear={handleClear}
    >
      <FormProvider {...formMethods}>
        <Grid sx={dropdownButtonStyles.container}>
          {fields.map((field: any) => (
            <SelectController
              fullWidth
              defaultValue={field.defaultValue}
              key={field.id}
              multiple={field.multiple}
              placeholder={field.placeholder}
              label={field.label}
              name={field.name}
              options={field.options}
            />
          ))}
        </Grid>
      </FormProvider>
    </PopperButton>
  );
};
