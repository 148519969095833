import { useMemo, useState } from 'react';

import { useGetPlansQuery, useUpdateUserMutation } from 'redux/apis/users/users-api';

import { Grid, Tab, Typography, styled } from '@mui/material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';

import { UserDetails } from './tabs/user-details';
import { PlanDetails } from './tabs/plan-details';
import { StorageDetails } from './tabs/storage-details';
import { AccessDetails } from './tabs/access-details';
import StyledForm from 'components/common/styled-form';
import DialogModal from 'components/modals/dialog-modal';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UsersEditValidationSchema } from 'pages/users/components/user-modal/validation';

import { useWindowWidth } from 'hooks/useWindowWidth';

import { IUser } from 'utils/types';
import {
  defaultValuesAdapter,
  handleSwitchUserTabByErrors,
  handleTransformUserData,
} from './utils';

import { filledButtonStyles, outlinedButtonStyles } from 'globalStyles';
import { tabsStyles, userModalStyles } from './styles';

interface IProps {
  open: boolean;
  user: IUser;
  handleClose: () => void;
}

const StyledTabList = styled(TabList)(tabsStyles.tabList);

const LinkTab = ({ ...props }) => <Tab {...props} sx={tabsStyles.tab} />;

export const UserModal = ({ open, user, handleClose }: IProps) => {
  useGetPlansQuery();

  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const windowWidth = useWindowWidth();

  const [activeTab, setActiveTab] = useState('1');

  const form = useForm({
    resolver: yupResolver(UsersEditValidationSchema),
    defaultValues: defaultValuesAdapter(user),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const handleChangeActiveTab = (_: React.SyntheticEvent, newValue: string) =>
    setActiveTab(newValue);

  const handleValidSave = (data: any) => {
    const transformedData = handleTransformUserData({
      ...data,
      tempPlanStatus: { ...data.tempPlanStatus },
    });

    updateUser({ body: transformedData, userId: user.id })
      .unwrap()
      .then(onClose)
      .catch((e: any) => console.log(e));
  };

  const handleInvalidSave = (errors: any) => {
    const switchTab = handleSwitchUserTabByErrors(errors);
    switchTab && setActiveTab(switchTab);
  };

  const onClose = () => {
    handleClose();
    form.reset();
  };

  const renderHeader = () => {
    return (
      <Grid container alignItems="center" sx={userModalStyles.formHeader}>
        <Typography sx={userModalStyles.editText}>Update User Information</Typography>
      </Grid>
    );
  };

  const tabListConditionalProps: any = useMemo(
    () =>
      windowWidth <= 768
        ? {
            variant: 'scrollable',
            scrollButtons: true,
            allowScrollButtonsMobile: true,
          }
        : {},
    [windowWidth]
  );

  const renderTabs = () => {
    return (
      <Grid sx={tabsStyles.tabsContainer}>
        <StyledTabList
          onChange={handleChangeActiveTab}
          aria-label="edit user tabs"
          {...tabListConditionalProps}
        >
          <LinkTab label="User Details" value="1" sx={tabsStyles.tab} />
          <LinkTab label="Plan Details" value="2" sx={tabsStyles.tab} />
          <LinkTab label="Storage Details" value="3" sx={tabsStyles.tab} />
          <LinkTab label="Access Details" value="4" sx={tabsStyles.tab} />
        </StyledTabList>
      </Grid>
    );
  };

  const renderTabContent = () => {
    return (
      <Grid sx={tabsStyles.tabsContent}>
        <TabPanel value="1" sx={tabsStyles.tabPanel}>
          <UserDetails />
        </TabPanel>
        <TabPanel value="2" sx={tabsStyles.tabPanel}>
          <PlanDetails userPlan={user?.plan} />
        </TabPanel>
        <TabPanel value="3" sx={tabsStyles.tabPanel}>
          <StorageDetails />
        </TabPanel>
        <TabPanel value="4" sx={tabsStyles.tabPanel}>
          <AccessDetails userEnterpriseProducts={user.enterpriseProducts} />
        </TabPanel>
      </Grid>
    );
  };

  const renderFooter = () => {
    return (
      <Grid container justifyContent="flex-end" sx={userModalStyles.buttonContainer}>
        <LoadingButton
          type="button"
          sx={[outlinedButtonStyles, userModalStyles.button]}
          loading={isLoading}
          onClick={onClose}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          type="submit"
          sx={[filledButtonStyles, userModalStyles.button]}
          loading={isLoading}
        >
          Save
        </LoadingButton>
      </Grid>
    );
  };

  return (
    <DialogModal
      withoutPadding
      maxWidth="650px"
      width="100%"
      withCloseButton={false}
      contentSx={userModalStyles.dialogModal}
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...form}>
        <StyledForm onSubmit={form.handleSubmit(handleValidSave, handleInvalidSave)}>
          {renderHeader()}
          <Grid container sx={userModalStyles.templateBody}>
            <TabContext value={activeTab}>
              {renderTabs()}
              {renderTabContent()}
            </TabContext>
            {renderFooter()}
          </Grid>
        </StyledForm>
      </FormProvider>
    </DialogModal>
  );
};
