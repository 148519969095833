export const filtersPopperStyles = {
  form: {
    width: '100%',
  },

  button: {
    width: '97px',
    height: '36px',
    border: '1px solid #EDEFF7',
    borderRadius: '8px',
    textTransform: 'unset',
    fontSize: '14px',
    lineHeight: '146%',
    fontWeight: '400',
    backgroundColor: '#fff',
    color: '#535F77',
    boxShadow: 'none',
    transition: '300ms',

    '&:hover': {
      backgroundColor: '#F3F4F9',
      border: '1px solid #EDEFF7',
      boxShadow: 'none',
      filter: 'drop-shadow(0px 0px 2px #4D6EF5)',
    },

    '&:active': { filter: 'drop-shadow(0px 0px 2px #4D6EF5)' },

    '&:focus': { filter: 'drop-shadow(0px 0px 2px #4D6EF5)' },
  },

  paperContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '385px',
    padding: '20px',
    gap: '24px',
    borderRadius: '12px',
  },

  buttonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '16px',
  },

  mainActionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '12px',
  },
};
