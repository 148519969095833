export const paginationSelectStyles = {
  container: {
    flexShrink: '0',
    '& .MuiSelect-select': {
      padding: '4px 0 5px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'unset',
    },
    '& svg': {
      top: 'unset',
    },
  },
};
