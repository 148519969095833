export const sidebarLinkTabStyles = {
  tab: {
    fontFamily: 'Inter, sans-serif !important',
    minHeight: '36px !important',
    textTransform: 'none',
    color: '#23252F',
    borderRadius: '12px',
    height: '36px',
    '& .svg-icon': {
      marginRight: '12px !important',
    },
    '&:hover': {
      backgroundColor: '#F2F5FF',
    },
  },
  icon: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  chevron: {
    justifyContent: 'right',
    display: 'flex',
    alignItems: 'center',
  },
};
