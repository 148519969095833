export const planModalStyles = {
  dialogModal: {
    '&::-webkit-scrollbar': {
      border: 'none',
      width: '3px',
      height: '5px',
      padding: '5px 0',
    },
    '&::-webkit-scrollbar-track': {
      border: 'none',
      height: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '2px',
      borderRadius: '4px',
      cursor: 'pointer',
    },
  },

  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '40px 40px 20px 40px',
    borderBottom: '1px solid #F3F4F9',
    borderRadius: '12px 12px 0px 0px',

    '@media (max-width: 768px)': {
      justifyContent: 'center',
      textAlign: 'center',
      padding: '15px 15px 15px 15px',
      gap: '15px',
    },
  },
  planHeaderText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '136%',
    color: '#23252F',

    '@media (max-width: 768px)': {
      fontSize: 18,
      lineHeight: '20px',
    },
  },
  planModalBody: {
    padding: '20px 40px 20px 40px',

    '@media (max-width: 768px)': {
      padding: '15px 15px 15px 15px',
    },
  },
  buttonContainer: { gap: '12px', marginTop: '40px' },
  createButton: {
    width: '101px',
    height: '48px',
  },
  cancelButton: {
    width: '70px',
    height: '48px',
  },
  visibleText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    textShadow: '1px 2px 2px rgba(35, 37, 47, 0.1)',
  },
};
