const AUTH_HEADERS: string[] = ['Authorization', 'company-id'];

interface Data {
  [x: string]: string;
}

export function setAuthData(data: Data): void {
  if (data) {
    AUTH_HEADERS.forEach((key) => {
      if (data[key]) {
        localStorage[key] = data[key];
      }
    });
  }
}

export function getAuthData() {
  const data: Data = {};
  AUTH_HEADERS.forEach((key) => {
    if (localStorage[key]) {
      data[key] = localStorage[key];
    }
  });
  return data;
}

export function clearAuthData() {
  console.warn('%c clearAuthData()', 'color: blue');

  [...AUTH_HEADERS].forEach((key) => delete localStorage[key]);

  localStorage.removeItem('userId');
}
