import { Grid } from '@mui/material';

import { ReactComponent as HelpIcon } from 'assets/icons/help-icon.svg';

import { helpMenuStyles } from './styles';

export const HelpPopperButton = () => {
  return (
    <Grid
      id="companies-list-button"
      //   aria-controls={false ? 'companies-list-button' : undefined}
      //   aria-expanded={false ? 'true' : undefined}
      aria-haspopup="true"
      sx={helpMenuStyles.questionContainer}
    >
      <HelpIcon />
    </Grid>
  );
};
