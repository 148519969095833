import React, { FC, memo } from 'react';
// mui components
import { CircularProgress, Grid } from '@mui/material';
// types
import { GridProps } from '@mui/material/Grid/Grid';

interface IProps {
  size?: number;
}

const Spinner: FC<GridProps & IProps> = ({ size = 70, ...gridProps }) => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    sx={{ minHeight: '100vh' }}
    {...gridProps}
  >
    <CircularProgress size={size} />
  </Grid>
);

export default memo(Spinner);
