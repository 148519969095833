import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// components
import { CustomDatePicker } from 'components/inputs/custom-date-picker/custom-date-picker';

interface OwnProps {
  name: string;
  label: string;
  fullWidth?: boolean;
  placeholder?: string;
  shouldUnregister?: boolean;
  additionalOnChange?: any;
  [x: string]: any;
}

const DatePickerController: React.FC<OwnProps> = ({
  name = '',
  withError = false,
  shouldUnregister = false,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState: { error } }) => {
        return (
          <CustomDatePicker
            {...props}
            name={field.name}
            value={field.value}
            error={!!error}
            helperText={withError ? error?.message || ' ' : undefined}
            onChange={field.onChange}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
};

export default memo(DatePickerController);
