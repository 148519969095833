import React, { useCallback, useEffect, useRef, useState } from 'react';
import stringToColor from 'string-to-color';
// mui components
import { ClickAwayListener, Grow, Paper, Popper, MenuList, Button, Grid } from '@mui/material';
import { StyledProfileMenuItem } from './profile-menu-item';
// icons
import { ReactComponent as ChevronDownLightIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as LogOutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left-rec.svg';
// custom hooks
import { useUserData } from 'hooks/useUserData';
// api
import { useLoginAsUserMutation } from 'redux/apis/users/users-api';
// utils
import { getImageBySize } from 'utils/helpers';

export const ProfilePopperButton: React.FC = () => {
  const [open, setOpen] = useState(false);

  const { userData, logOut } = useUserData();
  const [loginAsUser] = useLoginAsUserMutation();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }, []);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const switchToNormalAccount = useCallback(async () => {
    try {
      const token: any = await loginAsUser({ email: String(userData?.companyProfile?.email) });
      const inviteUrl = `${process.env.REACT_APP_DOMAIN}sign-up-invite?entryToken=${token?.data?.entryToken}`;

      window.location.href = inviteUrl;
    } catch (error: any) {
      console.log('Error:', error);
    }
  }, [loginAsUser]);

  return (
    <>
      <Button
        ref={anchorRef}
        variant="outlined"
        id="composition-button-avatar-button"
        aria-controls={open ? 'composition-menu-avatar-button' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={<ChevronDownLightIcon />}
        startIcon={
          userData?.companyProfile && userData?.companyProfile.avatar ? (
            <img src={getImageBySize(userData?.companyProfile.avatar, 40, 40)} alt="avatar" />
          ) : (
            <Grid
              component="span"
              sx={{
                backgroundColor: stringToColor(
                  `${userData?.companyProfile?.firstName[0]}${userData?.companyProfile?.lastName[0]}`
                ),
              }}
            >
              {`${userData?.companyProfile?.firstName[0] || ''}${
                userData?.companyProfile?.lastName[0] || ''
              }`}
            </Grid>
          )
        }
        sx={{
          justifyContent: 'space-between',
          marginLeft: '24px',
          textTransform: 'unset',
          color: '#fff',
          border: 'unset',
          padding: '0px',
          minWidth: 'auto',
          '&:hover': {
            border: 'unset',
          },
          '& .MuiButton-endIcon': {
            // marginLeft: '14px',
            '& svg': {
              width: '14px',
              display: 'none',
            },
          },
          '& .MuiButton-startIcon': {
            marginRight: '0px',
            marginLeft: '0px',

            '& span': {
              padding: '5px',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              width: '40px',
              height: '40px',
            },

            '& svg, img': {
              width: '40px',
              height: '40px',
              borderRadius: '8px',
            },
          },
        }}
      />
      <Popper
        transition
        open={open}
        role={undefined}
        placement="bottom-end"
        anchorEl={anchorRef.current}
        sx={{
          zIndex: 10,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-end' ? 'right top' : 'right bottom',
            }}
          >
            <Paper
              sx={{
                width: '240px',
                '& .MuiList-root': {
                  padding: 0,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu-avatar-button"
                  aria-labelledby="composition-button-avatar-button"
                  onKeyDown={handleListKeyDown}
                >
                  <StyledProfileMenuItem
                    onClick={(event: any) => {
                      handleClose(event);
                      switchToNormalAccount();
                    }}
                  >
                    <ArrowLeftIcon />
                    Normal Area
                  </StyledProfileMenuItem>

                  <StyledProfileMenuItem
                    onClick={(event: any) => {
                      handleClose(event);
                      logOut();
                    }}
                  >
                    <LogOutIcon />
                    Sign Out
                  </StyledProfileMenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
