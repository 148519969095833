export const customizedSelectStyles = {
  menu: {
    '& .MuiPopover-paper': {
      padding: '12px 5px',
      borderRadius: '12 px !important',
      boxShadow:
        '0px 4px 10px rgba(35, 37, 47, 0.1), 1px 2px 2px rgba(35, 37, 47, 0.04) !important',
    },
    '& .MuiPopover-paper > ul': {
      padding: '0',
      maxHeight: '355px !important',
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
    '& .MuiPopover-paper > ul::-webkit-scrollbar': {
      border: 'none',
      width: '3px',
      height: '5px',
      padding: '5px 0',
    },
    '& .MuiPopover-paper > ul::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      border: 'none',
      width: '10px',
    },
    '& .MuiPopover-paper > ul::-webkit-scrollbar-thumb': {
      backgroundColor: '#d4d6e0',
      width: '2px',
      borderRadius: '4px',
      cursor: 'pointer',
    },
  },

  menuItem: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#131F1E',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 16px',
    borderRadius: '8px',
    margin: '0 2px',

    '&:hover': {
      background: '#F2F5FF',
    },

    '& .checkIcon': { display: 'none' },

    '&[aria-selected="true"]': {
      '& .checkIcon': {
        display: 'inline-block !important',
      },
    },
  },

  menuItemContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#131F1E',
  },

  input: {
    maxWidth: '100%',
  },

  select: {
    width: '100%',
    height: '48px',

    '& .Mui-disabled': {
      opacity: '0.5',
    },

    '& .MuiPopover-paper': {
      padding: '12px 5px',
      borderRadius: '12 px !important',
      boxShadow:
        '0px 4px 10px rgba(35, 37, 47, 0.1), 1px 2px 2px rgba(35, 37, 47, 0.04) !important',
    },
    '& svg': {
      top: 'unset',
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',

      '& .MuiGrid-root': {
        maxWidth: 'calc(100% - 10px)',
        overflow: 'hidden',
        wordBreak: 'keep-all',
      },

      '& .checkIcon': {
        display: 'none !important',
      },
    },
  },
  placeholder: {
    opacity: 0.6,
  },
  inputTextContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '90%',
  },
  inputText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: '#23252F',
  },
};
