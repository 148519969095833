import { Grid, Typography } from '@mui/material';

import { iconMessageStyles } from './styles';

interface IProps {
  icon: React.ReactNode;
  text1: { size: string; text: string };
  text2?: { size: string; text: string };
}

export const IconMessage = ({ icon, text1, text2 }: IProps) => {
  return (
    <Grid sx={iconMessageStyles.container}>
      <Grid style={iconMessageStyles.icon}>{icon}</Grid>
      <Typography sx={[iconMessageStyles.text1, { fontSize: text1.size }]}>{text1.text}</Typography>
      {text2 && (
        <Typography sx={[iconMessageStyles.text2, { fontSize: text2.size }]}>
          {text2.text}
        </Typography>
      )}
    </Grid>
  );
};
