export const customAutocompleteStyles = {
  autocompletePaper: {
    borderRadius: '8px !important',
    boxShadow: '0px 4px 10px rgba(35, 37, 47, 0.1), 1px 2px 2px rgba(35, 37, 47, 0.04)',
    padding: '6px',
    '& .MuiAutocomplete-noOptions': {
      padding: '12px',
      fontSize: '14px',
      fontWeight: '400',
      color: '#23252F',
      fontFamily: 'Inter,sans-serif!important',
    },

    '& ul': {
      padding: '0',
      maxHeight: '355px !important',
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
    '& ul::-webkit-scrollbar': {
      border: 'none',
      width: '3px',
      height: '5px',
      padding: '5px 0',
    },
    '& ul::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      border: 'none',
      width: '10px',
    },
    '& ul::-webkit-scrollbar-thumb': {
      backgroundColor: '#d4d6e0',
      width: '2px',
      borderRadius: '4px',
      cursor: 'pointer',
    },
  },
  option: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#131F1E',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
    padding: '14px 16px !important',
    borderRadius: '8px',
    margin: '0 2px',

    '& .checkIcon': { display: 'none' },

    '&[aria-selected="true"]': {
      '& .checkIcon': {
        display: 'inline-block !important',
      },
    },
  },
  optionContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '146%',
    color: '#131F1E',
  },
  tagsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '6px',
    marginTop: '8px',
  },
};
