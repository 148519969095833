export const tableTopBarStyles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    marginBottom: '20px',
  },
  divider: {
    width: '1px',
    backgroundColor: '#F3F4F9',
  },
};
