import makeAxiosRequest from './axios.instance';

const api = {
  auth: {
    signIn: makeAxiosRequest('/auth/sign-in', 'post'),
    getProfiles: makeAxiosRequest('/profiles', 'get'),
    getUserProfile: makeAxiosRequest('/auth/me', 'get'),
  },
  // superAdmin: {
  //   getUsersList: makeAxiosRequest('/super-admin/users', 'get'),
  //   getUser: makeAxiosRequest((id) => `/super-admin/users/${id}`, 'get'),
  //   updateUser: makeAxiosRequest((id) => `/super-admin/users/${id}`, 'patch'),
  //   removeUser: makeAxiosRequest((id) => `/super-admin/users/${id}`, 'delete'),
  //   addSuperAdmin: makeAxiosRequest('/super-admin', 'post'),
  //   getUserToken: makeAxiosRequest('/super-admin/login-as-user', 'post'),
  //   getPaymentPlans: makeAxiosRequest('/super-admin/plans', 'get'),
  // },
};

export default api;
