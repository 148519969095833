export const planModalBodyStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '18px',
  },

  amountContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    gap: '16px',
  },
};
