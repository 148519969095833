import { Grid, Typography } from '@mui/material';
import { SwitchController } from 'controllers/switch-controller';
import { CustomSwitch } from '../custom-switch';

import { styles } from './styles';

interface IWithLabelCustomSwitch {
  label: string;
  [x: string]: any;
}

export const WithLabelCustomSwitch = ({
  label,
  withController = true,
  ...rest
}: IWithLabelCustomSwitch) => {
  return (
    <Grid sx={styles.container}>
      <Typography sx={styles.label}>{label}</Typography>
      {withController ? <SwitchController {...rest} /> : <CustomSwitch {...rest} />}
    </Grid>
  );
};
