import { Grid } from '@mui/material';

import { OutlinedInputController } from 'controllers/outlined-input-controller';

import { userDetailsStyles } from './styles';

export const UserDetails = () => {
  return (
    <>
      <Grid sx={userDetailsStyles.above}>
        <OutlinedInputController
          fullWidth
          withError
          name="firstName"
          label="First Name"
          placeholder="John"
        />
        <OutlinedInputController
          fullWidth
          withError
          name="lastName"
          label="Last Name"
          placeholder="Doe"
        />
      </Grid>
      <Grid sx={userDetailsStyles.below}>
        <OutlinedInputController
          fullWidth
          withError
          name="email"
          label="Email Address"
          placeholder="example@gmail.com"
        />
      </Grid>
    </>
  );
};
