import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { ReactComponent as PaginationArrowDownIcon } from 'assets/icons/pagination-arrow-down.svg';

import { paginationSelectStyles } from './styles';

interface IProps {
  values: number[];
  value: number;
  totalItemsCount?: number;
  onChange: (event: SelectChangeEvent) => void;
}

export const PaginationSelect = ({ values, value, totalItemsCount, onChange }: IProps) => {
  return (
    <Select
      sx={paginationSelectStyles.container}
      value={`${value}`}
      onChange={onChange}
      IconComponent={PaginationArrowDownIcon}
      MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
    >
      {values.map((item: number, index: number) => (
        <MenuItem
          key={index}
          value={item}
          disabled={totalItemsCount !== undefined && totalItemsCount < item}
        >
          {item}
        </MenuItem>
      ))}
    </Select>
  );
};
