export enum PlanTypeEnum {
  BASE_PLAN = 'Base Plan',
  CUSTOM_PLAN = 'Custom Plan',
  STORAGE_PLAN = 'Storage Plan',
  ENTERPRICE = 'Enterprice',
}

export const planTypesFields = [
  { id: 1, label: 'Base Plan', value: 'Base Plan' },
  { id: 2, label: 'Custom Plan', value: 'Custom Plan' },
  { id: 3, label: 'Storage Plan', value: 'Storage Plan' },
];

export const paymentFrequencyFields = [
  { id: 1, label: 'Monthly', value: 'monthly' },
  { id: 2, label: 'Yearly', value: 'yearly' },
];

export const mockPlanTableData = [
  {
    id: 1,
    type: 'Base Plan',
    name: 'Base Plan #',
    ofProjects: 15,
    ofUsers: 5,
    mohtlyAmount: '$15',
    annualAmount: '20$',
  },
  {
    id: 2,
    type: 'Enterprice',
    name: 'Base Plan #',
    ofProjects: 15,
    ofUsers: 5,
    mohtlyAmount: '$15',
    annualAmount: '20$',
  },
  {
    id: 3,
    type: 'Custom Plan',
    name: 'Base Plan #',
    ofProjects: 15,
    ofUsers: 5,
    mohtlyAmount: '$15',
    annualAmount: '20$',
  },
  {
    id: 4,
    type: 'Storage Plan',
    name: 'Base Plan #',
    ofProjects: 15,
    ofUsers: 5,
    mohtlyAmount: '$15',
    annualAmount: '20$',
  },
  {
    id: 5,
    type: 'Base Plan',
    name: 'Base Plan #',
    ofProjects: 15,
    ofUsers: 5,
    mohtlyAmount: '$15',
    annualAmount: '20$',
  },
];
