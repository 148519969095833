export const sidebarStyles = {
  container: {
    width: 'max-content',
    minHeight: 'calc(100vh - 121px)',
    backgroundColor: '#FFF',
    border: '1px solid #F3F4F9',
  },

  tabList: {
    width: '237px',
    padding: '8px 13px',
    '& .MuiTabs-flexContainer': {
      gap: '8px',
    },
    '& .Mui-selected': {
      backgroundColor: '#4D6EF5',
      color: '#FFFFFF !important',
      '&:hover': {
        backgroundColor: '#3652C6',
      },
    },
    fontFamily: 'Inter, sans-serif !important',
  },

  iconButton: {
    position: 'fixed',
    padding: '12px',
    left: '-0',
    transform: 'translateY(-50%)',
    top: '50%',
    backgroundColor: 'rgb(35, 37, 47)',
    borderRadius: '0 12px 12px 0',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'rgba(35, 37, 47, 0.8)',
    },
    svg: {
      width: '20px',
      height: '20px',
    },
    zIndex: '10',
  },

  closeButton: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
};
