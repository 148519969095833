import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      headers.set('authorization', localStorage.getItem('Authorization') ?? '');
    },
  }),
  keepUnusedDataFor: 10,
  tagTypes: ['Users'],
  endpoints: () => ({}),
});
