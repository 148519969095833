import { useState } from 'react';

import { Grid } from '@mui/material';

import { PlanTableTopBar } from './components/plan-table-top-bar';
import { PlanTable } from './components/plan-table';
import { PlanModal } from './components/plan-modal';

import { planStyles } from './styles';

export const Plans = () => {
  const [planModalValue, setPlanModalValue] = useState<any>(null);

  const getAddValue = (planType: string) => setPlanModalValue(planType);
  const handlePlanModalClose = () => setPlanModalValue(null);

  return (
    <Grid sx={planStyles.container}>
      <PlanTableTopBar getAddValue={getAddValue} />
      <PlanTable />
      {planModalValue && (
        <PlanModal open handleClose={handlePlanModalClose} plan={planModalValue} />
      )}
    </Grid>
  );
};
