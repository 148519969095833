import { NumberFormatValues } from 'react-number-format';

export const isAllowed = (values: NumberFormatValues) => {
  const stringFloatValue = String(values.floatValue);
  const isFirstPartValid = stringFloatValue.split('.')[0].length <= 6;

  if (isFirstPartValid || !values.value) return true;
  return false;
};

export const isAllowedStorage = (values: NumberFormatValues) => {
  if (values?.value.length <= 6) return true;
  return false;
};

export const amoutInputConfig = {
  withError: true,
  showStartAdornment: true,
  adornmentContent: '$',
  isAllowed: isAllowed,
  fullWidth: true,
  placeholder: '0.00',
  thousandSeparator: ',',
  decimalSeparator: '.',
  decimalScale: 2,
  fixedDecimalScale: true,
  allowNegative: false,
};
