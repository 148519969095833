import { useMemo } from 'react';

import { useQueries } from 'hooks/useQueries/useQueries';

import { TableTopBar } from 'components/table/table-top-bar';

import { useGetPlansQuery } from 'redux/apis/users/users-api';

import { creationTimeFields, statusFields, typeFields } from './utils';
import { plansAdapter } from 'pages/users/utils';

export const UserTableTopBar = () => {
  const { data }: any = useGetPlansQuery();
  const plans = data?.items;

  const { setQueries, parsedQueries } = useQueries();
  const { productIds, type, status, orderBy, search } = parsedQueries;

  const planFields = useMemo(() => {
    return plansAdapter(plans, true);
  }, [JSON.stringify(plans)]);

  const defaultValues: any = {
    filter: { type: type || ' ', productIds: productIds || [], status: status || '' },
    sort: { orderBy: orderBy || '' },
    search: search || '',
  };

  const fields = {
    filterFields: [
      {
        placeholder: 'Select Type',
        name: 'type',
        label: 'Type',
        options: typeFields,
        id: 1,
      },
      {
        placeholder: 'Select Plan',
        multiple: true,
        name: 'productIds',
        label: 'Plan',
        options: planFields,
        id: 2,
      },
      {
        placeholder: 'Select Status',
        name: 'status',
        label: 'Status',
        options: statusFields,
        id: 3,
      },
    ],
    sortFields: [
      {
        placeholder: 'Order By',
        name: 'orderBy',
        label: 'Order By',
        options: creationTimeFields,
        defaultValue: '',
        id: 1,
      },
    ],
  };

  const updateQueries = (formData: any) => {
    setQueries({ page: '1', ...formData });
  };

  const getDebouncedSearch = (value: string) => {
    updateQueries({ search: value });
  };

  return (
    <TableTopBar
      fields={fields}
      defaultValues={defaultValues}
      getDebouncedSearch={getDebouncedSearch}
      getSortingValues={updateQueries}
      getFilteringValues={updateQueries}
      onSortingClear={updateQueries}
      onFilteringClear={updateQueries}
    />
  );
};
