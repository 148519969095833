import { TableTopBar } from 'components/table/table-top-bar';

import { useQueries } from 'hooks/useQueries/useQueries';

import { addNewFields, planTypesFilterFields } from './utils';

interface IProps {
  getAddValue?: (value: string) => void;
}

export const PlanTableTopBar = ({ getAddValue }: IProps) => {
  const { setQueries, parsedQueries } = useQueries();

  const defaultValues: any = {
    filter: { type: parsedQueries.type || '' },
    search: parsedQueries.search || '',
  };

  const fields = {
    filterFields: [
      {
        placeholder: 'Select Plan Type',
        name: 'type',
        label: 'Type',
        options: planTypesFilterFields,
        id: 1,
      },
    ],
    addFields: addNewFields,
  };
  const updateQueries = (formData: any, page = '1') => {
    setQueries({ page, ...formData });
  };

  const getDebouncedSearch = (value: string) => updateQueries({ search: value });

  return (
    <TableTopBar
      fields={fields}
      defaultValues={defaultValues}
      getAddValue={getAddValue}
      getFilteringValues={updateQueries}
      onFilteringClear={updateQueries}
      getDebouncedSearch={getDebouncedSearch}
    />
  );
};
