import { Link } from 'react-router-dom';

import { Grid, Tab, Typography } from '@mui/material';

import { sidebarLinkTabStyles } from './styles';

export const SidebarLinkTab = ({ href, icon, label, chevron, ...props }: any) => {
  const renderLabel = () => (
    <>
      <Grid item xs={10} sx={sidebarLinkTabStyles.icon}>
        {icon}
        <Typography fontSize={14}>{label}</Typography>
      </Grid>
      <Grid item xs={2} sx={sidebarLinkTabStyles.chevron}>
        {chevron}
      </Grid>
    </>
  );

  return (
    <Tab
      component={Link}
      iconPosition="start"
      to={href}
      sx={sidebarLinkTabStyles.tab}
      label={renderLabel()}
      {...props}
    />
  );
};
