import { Skeleton, TableCell, TableRow } from '@mui/material';

import { skeletonRowStyles } from './styles';

interface IProps {
  length: number;
}

export const SkeletonRow = ({ length }: IProps) => {
  return (
    <TableRow sx={skeletonRowStyles.tableRow}>
      {Array.from({ length }).map((_, index) => (
        <TableCell key={index} align="center">
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
      ))}
    </TableRow>
  );
};
