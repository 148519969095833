import { Box } from '@mui/material';

import { PlanStatusEnum } from 'pages/users/entities';

import { userStatusStyles } from './styles';

interface UserStatusProps {
  status: PlanStatusEnum;
  fullWidth?: boolean;
}

const statusMappings = {
  ACTIVE: {
    style: userStatusStyles.active,
    text: 'Active',
  },
  INACTIVE: {
    style: userStatusStyles.canceled,
    text: 'Canceled',
  },
  EXPIRED: {
    style: userStatusStyles.expired,
    text: 'Expired',
  },
  TRIALING: {
    style: userStatusStyles.trialing,
    text: 'Trialing',
  },
  PENDING: {
    style: userStatusStyles.pending,
    text: 'Pending',
  },
};

export const PlanStatus = ({ status, fullWidth = false }: UserStatusProps) => {
  if (!statusMappings.hasOwnProperty(status)) {
    console.error(`Invalid status: ${status}`);
    return null;
  }

  const { style = {}, text = '' } = statusMappings[status] || {};

  return (
    <Box
      component="span"
      sx={[userStatusStyles.box, style, fullWidth && userStatusStyles.fullWidth]}
    >
      {text}
    </Box>
  );
};
