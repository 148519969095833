import * as yup from 'yup';

import { emailRule } from 'utils/validation-rules';

import { PlanNameEnum, PlanStatusEnum } from 'pages/users/entities';

export const UsersEditValidationSchema = yup
  .object({
    //First Name
    firstName: yup
      .string()
      .required('First Name is required!')
      .min(2, 'First Name must contain at least 2 characters'),
    //Last Name
    lastName: yup
      .string()
      .required('Last Name is required!')
      .min(2, 'First Name must contain at least 2 characters'),
    // Email
    email: emailRule,
  })
  .test(
    'name-status-dependency',
    'Both name and status are mutually required',
    function (values: any) {
      const isPlanExist = !values?.plan;

      const tempPlanName: any = values?.tempPlanName;
      const tempPlanStatus = values?.tempPlanStatus?.currentTempPlanStatus;

      const nextChargeDate = values?.plan?.nextChargeDate;
      const trialEndDate = values?.plan?.trialEndDate;

      if (
        !isPlanExist ||
        tempPlanName === PlanNameEnum.FIRST_PROJECT_FREE ||
        tempPlanName === PlanNameEnum.LIFETIME_DEAL
      ) {
        return true;
      }

      if (
        tempPlanName === PlanNameEnum.TRIAL &&
        tempPlanStatus === PlanStatusEnum.TRIALING &&
        !trialEndDate
      ) {
        return this.createError({
          path: 'plan.trialEndDate',
          message: 'Trial end date is required when plan is trialing!',
        });
      }

      if (
        tempPlanName !== PlanNameEnum.TRIAL &&
        tempPlanStatus === PlanStatusEnum.TRIALING &&
        !nextChargeDate
      ) {
        return this.createError({
          path: 'plan.nextChargeDate',
          message: 'Next charge date is required!',
        });
      }

      if (
        tempPlanName !== PlanNameEnum.TRIAL &&
        tempPlanStatus === PlanStatusEnum.ACTIVE &&
        !nextChargeDate
      ) {
        return this.createError({
          path: 'plan.nextChargeDate',
          message: 'Next charge date is required when plan is active!',
        });
      }

      if (
        tempPlanName === PlanNameEnum.TRIAL &&
        tempPlanStatus === PlanStatusEnum.ACTIVE &&
        !trialEndDate
      ) {
        return this.createError({
          path: 'plan.trialEndDate',
          message: 'Trial end date is required when plan is active!',
        });
      }

      return true; // Passes validation
    }
  );
