import { Grid } from '@mui/material';
import { WithLabelCustomSwitch } from 'components/inputs/with-label-custom-switch';
import { EnterprisePlans } from './EnterprisePlans';

import { accessDetailsStyles } from './styles';

interface IAccessDetails {
  userEnterpriseProducts: any[] | [];
}

export const AccessDetails = ({ userEnterpriseProducts }: IAccessDetails) => {
  return (
    <Grid sx={accessDetailsStyles.container}>
      <WithLabelCustomSwitch label="Super Admin" name="isSuperAdmin" withTick />
      <WithLabelCustomSwitch label="Beta Features" disabled />
      <EnterprisePlans userEnterpriseProducts={userEnterpriseProducts} />
    </Grid>
  );
};
