import { useEffect, useMemo } from 'react';
import { Routes, Route, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// pages
import SignIn from '../sign-in';
import { Users } from '../users';
import { Plans } from 'pages/plans';
import { NotFound } from 'pages/not-found';
// mui components
import { Grid } from '@mui/material';
// components
import Spinner from 'components/spinner';
import { Header } from 'components/header';
// custom hooks
import { useUserData } from 'hooks/useUserData';
import { useAuth } from 'hooks/useAuth';
// api
import { useOneTimeEntryMutation } from 'redux/apis/users/users-api';
// layouts
import { SidebarLayout } from 'layouts/sidebar-layout';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { userData, pending, updateUserData, logOut } = useUserData();

  const { handleAuth } = useAuth();
  const [oneTimeEntry] = useOneTimeEntryMutation();

  useEffect(() => {
    const token = localStorage.getItem('Authorization');

    if (token && userData && userData.isAuthorized === null) {
      handleAuth();
      updateUserData({ isAuthorized: true });
      location.pathname === '/' && navigate('/users');
    } else if (!token && (!userData || userData?.isAuthorized === null)) {
      updateUserData({
        isAuthorized: false,
        companyProfile: undefined,
        companySetup: undefined,
      });

      navigate('/sign-in');
    }
  }, [userData, updateUserData, handleAuth, navigate]);

  useEffect(() => {
    const clearData = () => {
      const token = localStorage.getItem('Authorization');

      if (!token) {
        updateUserData({ isAuthorized: false, companyProfile: undefined, companySetup: undefined });
        navigate('/sign-in');
      }
    };

    window.addEventListener('storage', clearData);

    return () => window.removeEventListener('storage', clearData);
  }, [updateUserData, navigate]);

  useEffect(() => {
    if (location.search) {
      const entryToken = searchParams.get('entryToken');

      if (entryToken) {
        if (userData.isAuthorized) {
          logOut();
        }

        oneTimeEntry({ entryToken })
          .unwrap()
          .then(async (res) => {
            searchParams.delete('entryToken');
            setSearchParams(searchParams);
            navigate('/users');

            await handleAuth();
            localStorage.setItem('userId', res.data.id);
            updateUserData({ isAuthorized: true });
          });
      }
    }
  }, [location]);

  const renderHeader = useMemo(() => {
    const pagesWithoutHeader = ['/sign-in'];

    if (!pagesWithoutHeader.some((page) => location.pathname.includes(page))) {
      return <Header />;
    }
  }, [location.pathname]);

  if (pending || userData?.isAuthorized === null) {
    return <Spinner />;
  }

  return (
    <Grid>
      {renderHeader}
      <Routes location={location}>
        <Route path="*" element={<NotFound />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route element={<SidebarLayout />}>
          <Route path="/users" element={<Users />} />
          <Route path="/plans" element={<Plans />} />
        </Route>
      </Routes>
    </Grid>
  );
}

export default App;
