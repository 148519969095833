import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import StyledForm from 'components/common/styled-form';
import DialogModal from 'components/modals/dialog-modal';
import { PlanModalBody } from './plan-modal-body';

import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';

import { SwitchController } from 'controllers/switch-controller';

import { yupResolver } from '@hookform/resolvers/yup';

import { PlansValidationSchema } from './validation';

import { PlanTypeEnum } from 'pages/plans/utils';

import { planModalStyles } from './styles';
import { filledButtonStyles, outlinedButtonStyles, flexAlignCenter } from 'globalStyles';

interface IProps {
  open: boolean;
  plan?: any;
  handleClose: () => void;
}

export const BaseSwitch = () => {
  const { control } = useFormContext();
  const planType = useWatch({ control, name: 'type' });

  if (planType !== PlanTypeEnum.BASE_PLAN) return null;

  return (
    <Grid sx={[flexAlignCenter, { gap: '4px' }]}>
      <SwitchController shouldUnregister name="isBaseVisible" isSmall />
      <Typography sx={planModalStyles.visibleText}>Visible</Typography>
    </Grid>
  );
};

export const PlanModal = ({ open, handleClose, plan }: IProps) => {
  const formMethods = useForm({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    resolver: yupResolver(PlansValidationSchema),
    defaultValues: {
      type: plan,
    },
  });

  const handleApply = (data: any) => {
    console.log(data, 'data');
  };

  const renderHeader = () => {
    return (
      <Grid container alignItems="center" sx={planModalStyles.formHeader}>
        <Typography sx={planModalStyles.planHeaderText}>Create Plan</Typography>
        <BaseSwitch />
      </Grid>
    );
  };

  const renderFooter = () => {
    return (
      <Grid container justifyContent="flex-end" sx={planModalStyles.buttonContainer}>
        <LoadingButton
          sx={[outlinedButtonStyles, planModalStyles.cancelButton]}
          type="button"
          onClick={handleClose}
        >
          Cancel
        </LoadingButton>
        <LoadingButton sx={[filledButtonStyles, planModalStyles.createButton]} type="submit">
          Create
        </LoadingButton>
      </Grid>
    );
  };

  return (
    <DialogModal
      withoutPadding
      maxWidth="650px"
      width="100%"
      withCloseButton={false}
      contentSx={planModalStyles.dialogModal}
      open={open}
      handleClose={handleClose}
    >
      <FormProvider {...formMethods}>
        <StyledForm onSubmit={formMethods.handleSubmit(handleApply)}>
          {renderHeader()}
          <Grid sx={planModalStyles.planModalBody}>
            <PlanModalBody />
            {renderFooter()}
          </Grid>
        </StyledForm>
      </FormProvider>
    </DialogModal>
  );
};
