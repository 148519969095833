import { useCallback, useMemo } from 'react';

import { TableCell, TableRow } from '@mui/material';

import { CustomTable } from 'components/table/custom-table';
import { PlanStatus } from 'components/plan-status';
import { UserTableActionsPopper } from './components/user-table-actions-popper';

import { capitalize } from 'lodash';

import { dateTimeFormatter } from 'utils/helpers';
import { IUser } from 'utils/types';
import { PlanStatusEnum } from 'pages/users/entities';

import { userTableStyles } from './styles';

export const UserTable = ({ handleEditUser, handleLoginAsUser, usersList, isLoading }: any) => {
  const headerCells = useMemo(
    () => ['First Name', 'Last Name', 'Email', 'Creation Date', 'Type', 'Plan', 'Status', ''],
    []
  );

  const UserRow = useCallback(
    (user: IUser) => (
      <TableRow key={user.id} sx={userTableStyles.tableRow}>
        <TableCell component="th" scope="row" sx={userTableStyles.firstName}>
          {user.firstName}
        </TableCell>
        <TableCell align="left" sx={userTableStyles.lastName}>
          {user.lastName}
        </TableCell>
        <TableCell align="left" sx={userTableStyles.email}>
          {user.email}
        </TableCell>
        <TableCell align="left" sx={userTableStyles.creationDate}>
          {user?.createdAt ? dateTimeFormatter(user.createdAt) : '-'}
        </TableCell>
        <TableCell align="left" sx={userTableStyles.type}>
          {capitalize(user.type)}
        </TableCell>
        <TableCell align="left" sx={userTableStyles.planName}>
          {user.plan?.name || '-'}
        </TableCell>
        <TableCell align="center" sx={userTableStyles.planStatus}>
          {user.plan?.status || user.plan?.isPendingForCancel ? (
            <PlanStatus
              status={user.plan?.isPendingForCancel ? PlanStatusEnum.PENDING : user.plan.status}
            />
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell align="center" sx={userTableStyles.action}>
          <UserTableActionsPopper
            handleEditUser={() => handleEditUser(user)}
            handleLoginAsUser={() => handleLoginAsUser(user?.email)}
          />
        </TableCell>
      </TableRow>
    ),
    [handleEditUser, handleLoginAsUser]
  );

  return (
    <CustomTable
      tableData={usersList?.items}
      tableMeta={usersList?.meta}
      isLoading={isLoading}
      headerCells={headerCells}
      renderRow={UserRow}
    />
  );
};
