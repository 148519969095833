import { PlanStatusEnum } from 'pages/users/entities';
import { IUser, IUserPlan } from 'utils/types';

export const handleTransformUserData = (data: any) => {
  delete data.tempPlanName;
  delete data.tempPlanStatus;
  delete data?.plan.name;
  delete data?.storageAmount;

  if (data?.enterpriseProducts.length) {
    data.enterpriseProducts = data?.enterpriseProducts.map((ep: any) => ep?.id);
  }

  return data;
};

export const handleSwitchUserTabByErrors = (errors: any) => {
  if (errors.firstName || errors.lastName || errors.email) return '1';
  if (errors.plan) return '2';

  return false;
};

const setDate = (
  name: any,
  status: any,
  trialEndDate: any,
  nextChargeDate: any,
  adaptedPlanFields: any
) => {
  if (nextChargeDate) {
    adaptedPlanFields.nextChargeDate = nextChargeDate;
    return;
  }

  if (name !== 'Trial' && status === PlanStatusEnum.TRIALING) {
    adaptedPlanFields.nextChargeDate = trialEndDate;
    return;
  }

  if (trialEndDate) {
    adaptedPlanFields.trialEndDate = trialEndDate;
    return;
  }
};

export const defaultValuesAdapter = (user: IUser) => {
  const { firstName, lastName, email, plan, isSuperAdmin = false } = user;
  const enterpriseProducts = user.enterpriseProducts || [];
  const tempPlanName = plan?.name;
  const tempPlanStatus = { prevTempPlanStatus: null, currentTempPlanStatus: plan?.status };

  let adaptedPlan: IUserPlan | null = null;

  if (user?.plan) {
    const { productId, status, name, trialEndDate, nextChargeDate } = user.plan;

    const adaptedPlanFields: IUserPlan = {
      productId,
      status,
    };

    if (name !== 'Trial' && status === PlanStatusEnum.TRIALING) {
      adaptedPlanFields.nextChargeDate = trialEndDate;
    } else if (trialEndDate) {
      adaptedPlanFields.trialEndDate = trialEndDate;
    } else if (nextChargeDate) {
      adaptedPlanFields.nextChargeDate = nextChargeDate;
    }

    adaptedPlan = adaptedPlanFields;
  }

  return {
    tempPlanName,
    tempPlanStatus,
    firstName,
    lastName,
    email,
    isSuperAdmin,
    enterpriseProducts,
    plan: adaptedPlan,
  };
};
