export const typeFields = [
  { label: 'Account Owner', value: 'ACCOUNT_OWNER', id: 0 },
  { label: 'User', value: 'USER', id: 1 },
  { label: 'Both', value: ' ', id: 2 },
];

export const statusFields = [
  { label: 'Active', value: 'ACTIVE', id: 0 },
  { label: 'Inactive', value: 'INACTIVE', id: 1 },
  { label: 'Trialing', value: 'TRIALING', id: 2 },
  { label: 'Pending Cancellation', value: 'PENDING_CANCELLATION', id: 3 },
];

export const creationTimeFields = [
  { label: 'Order By Ascending', value: 'asc', id: 0 },
  { label: 'Order By Descending', value: 'desc', id: 1 },
];
