import { NumbericFormatInput } from 'components/inputs/numeric-format-input';

import { Controller, useFormContext } from 'react-hook-form';

interface IProps {
  name: string;
  withError?: boolean;
  defaultValue?: string;
  label?: string;
  [x: string]: any;
}

export const NumericFormatInputController = ({
  name,
  withError,
  defaultValue,
  ...rest
}: IProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <NumbericFormatInput
            name={name}
            value={value}
            error={!!error}
            onChange={onChange}
            helperText={withError ? error?.message || ' ' : ' '}
            {...rest}
          />
        );
      }}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};
