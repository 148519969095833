import { format } from 'date-fns';

export const getImageBySize = (url: string | object, w: number, h: number, fitIn = true) => {
  if (typeof url === 'string') {
    const urlPathnameSplitted = new URL(url).pathname.split('/');
    const sliceIndex = urlPathnameSplitted.findIndex((str) =>
      str.includes('builderpad-backend-infra')
    );
    const lastPart = urlPathnameSplitted.slice(sliceIndex + 1).join('/');
    return `${process.env.REACT_APP_CLOUD_FRONT}${fitIn ? '/fit-in' : ''}/${Math.ceil(
      w
    )}x${Math.ceil(h)}/${lastPart}`;
  }
};

export const getElementWidthById = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    return element.getBoundingClientRect().width;
  }

  return null;
};

export const dateTimeFormatter = (inputDate: string) => {
  const parsedDate = new Date(inputDate);
  const formattedDate = format(parsedDate, "MMM d, yyyy 'at' h:mmaaa");
  return formattedDate;
};

export const addDayToDate = (date: string | Date | undefined | null, day = 1) => {
  if (!date) return null;

  const originalDate = new Date(date);
  const newDate = new Date(originalDate);
  newDate.setDate(newDate.getDate() + day);

  return newDate;
};
