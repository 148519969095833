// routing
import { Link } from 'react-router-dom';
// mui components
import { Divider, Grid } from '@mui/material';
// components
import { ProfilePopperButton } from './components/ProfilePopperButton/profile-popper-button';
import { HelpPopperButton } from './components/HelpPopperButton/help-popper-button';
// icons
import { ReactComponent as BuilderPadLogo } from 'assets/icons/logo-builder-pad.svg';
// styles
import { headerStyles } from './styles';

export const Header: React.FC = () => {
  return (
    <Grid container component="header" sx={headerStyles.header}>
      <Grid item sx={headerStyles.logoContainer}>
        <Link to="/users">
          <BuilderPadLogo width={127} height={28} />
        </Link>
      </Grid>
      <Grid item sx={headerStyles.popperButtonContainer}>
        <HelpPopperButton />
        <Divider orientation="vertical" flexItem sx={headerStyles.divider}></Divider>
        <ProfilePopperButton />
      </Grid>
    </Grid>
  );
};
