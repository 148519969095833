import { styled } from '@mui/material';

export const StyledLabel = styled('label')(() => ({
  width: '100%',
  marginBottom: '8px',
  fontSize: '14px',
  lineHeight: '146%',
  fontWeight: 400,
  color: '#23252F',
  fontFamily: 'Inter, sans-serif !important',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
