import * as yup from 'yup';

export const INVALID_EMAIL = 'Please use valid format of Email address';
export const INVALID_PASSWORD =
  'Please, use min 8 characters including a mix of letters, numbers & symbols';
export const INVALID_CONFIRM_PASSWORD = "Password doesn't match";

export const isEmail =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isPassword =
  // eslint-disable-next-line max-len
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$%^&*()_+|~\-=`{}[\]:";'<>?,./@#])[A-Za-z\d!$%^&*()_+|~\-=`{}[\]:";'<>?,./@#]{8,50}$/;

export const emailRule = yup
  .string()
  .required('Email is required!')
  .matches(isEmail, INVALID_EMAIL);

export const passwordRule = yup
  .string()
  .required('Field is required!')
  .min(8, 'Min 8 characters')
  .matches(isPassword, INVALID_PASSWORD);

export const confirmPasswordRule = (name: string) =>
  passwordRule.oneOf([yup.ref(name)], INVALID_CONFIRM_PASSWORD);
