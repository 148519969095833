import React, { createContext, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
// helpers
import { clearAuthData } from 'utils/authData';
import { IProjectItem, IUserPermissions } from 'utils/types';

export interface ICompanyProfile {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
  roles: { id: number; roleId: number; name: string; isOwner: boolean }[];
  avatar: string;
  userId: number;
  email: string;
  timezone: string | null;
}

export interface ICompanySetup {
  id: number;
  buildingStatisticId: number;
  phone: string;
  country: string;
  state: string;
  city: string;
  zip: string;
  logo: string;
  address: string;
  lat: number;
  lng: number;
  name: string;
  projects: IProjectItem[];
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
}

export interface IDataFromIp {
  country_code: string;
  country_name: string;
  city: string;
  postal: string;
  latitude: number;
  longitude: number;
  IPv4: string;
  state: string;
}

export interface IUserData {
  dataFromIp: IDataFromIp | null;
  isAuthorized: boolean | null;
  companyProfile: ICompanyProfile | null | undefined;
  companySetup: ICompanySetup | null | undefined;
  userPermissions: IUserPermissions;
  isOwner?: boolean | null;
}

interface IUpdateUserData {
  dataFromIp?: IDataFromIp | null;
  isAuthorized?: boolean | null;
  companyProfile?: ICompanyProfile | null;
  companySetup?: ICompanySetup | null;
  userPermissions?: IUserPermissions;
  isOwner?: boolean | null;
}

interface IContextProps {
  userData: IUserData;
  pending: boolean;
  handleSetPending: (value: boolean) => void;
  updateUserData: (values: IUpdateUserData) => void;
  logOut: () => void;
}

const UserDataContext = createContext({} as IContextProps);

export const useUserData = () => useContext(UserDataContext);

const defaultValues = {
  dataFromIp: null,
  isAuthorized: null,
  isOwner: null,
  companyProfile: null,
  companySetup: null,
  userPermissions: {
    PROJECT: {
      canCreate: false,
      canDelete: false,
      canRead: false,
      canUpdate: false,
    },
    SCHEDULE: {
      canCreate: false,
      canDelete: false,
      canRead: false,
      canUpdate: false,
    },
    ACTIVITY_FEED: {
      canCreate: false,
      canDelete: false,
      canRead: false,
      canUpdate: false,
    },
    USER_MANAGEMENT: {
      canCreate: false,
      canDelete: false,
      canRead: false,
      canUpdate: false,
    },
    ACCOUNT_SETTINGS: {
      canCreate: false,
      canDelete: false,
      canRead: false,
      canUpdate: false,
    },
    SCHEDULE_TEMPLATE: {
      canCreate: false,
      canDelete: false,
      canRead: false,
      canUpdate: false,
    },
    COMPANY_MANAGEMENT: {
      canCreate: false,
      canDelete: false,
      canRead: false,
      canUpdate: false,
    },
    SCHEDULE_PHASES_DATES: {
      canRead: false,
    },
    SCHEDULE_PHASES_ITEMS_DATES: {
      canRead: false,
    },
    PROJECTS_CONTACTS: {
      canRead: false,
    },
  },
};

interface OwnProps {
  children: React.ReactNode;
}

export const UserDataProvider: React.FC<OwnProps> = ({ children }) => {
  const navigate = useNavigate();

  const { cache } = useSWRConfig();

  const [userData, setUserData] = useState<IUserData>(defaultValues);

  const [pending, setPending] = useState(false);

  const handleSetPending = useCallback((value: boolean) => {
    setPending(value);
  }, []);

  const updateUserData = useCallback((values: IUpdateUserData) => {
    setUserData((prevState) => ({ ...prevState, ...values }));
  }, []);

  const logOut = useCallback(() => {
    setUserData({ ...defaultValues, isAuthorized: false });

    clearAuthData();

    //@ts-ignore
    cache.clear();

    navigate('/sign-in');
  }, [cache, navigate]);

  return (
    <UserDataContext.Provider
      value={{
        userData,
        pending,
        handleSetPending,
        updateUserData,
        logOut,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
