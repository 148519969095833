import { PlanTypeEnum } from 'pages/plans/utils';

export const planTypesFilterFields = [
  { label: PlanTypeEnum.BASE_PLAN, value: 'BASE_PLAN', id: 0 },
  { label: PlanTypeEnum.CUSTOM_PLAN, value: 'CUSTOM_PLAN', id: 1 },
  { label: PlanTypeEnum.STORAGE_PLAN, value: 'STORAGE_PLAN', id: 2 },
  { label: PlanTypeEnum.ENTERPRICE, value: 'ENTERPRICE', id: 3 },
];

export const addNewFields = [
  { label: PlanTypeEnum.BASE_PLAN, value: PlanTypeEnum.BASE_PLAN, id: 0 },
  { label: PlanTypeEnum.CUSTOM_PLAN, value: PlanTypeEnum.CUSTOM_PLAN, id: 1 },
  { label: PlanTypeEnum.STORAGE_PLAN, value: PlanTypeEnum.STORAGE_PLAN, id: 2 },
];
