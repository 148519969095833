import { SelectProps } from '@mui/material';

import { CustomAutocomplete } from 'components/inputs/custom-autocomplete';

import { Controller, useFormContext } from 'react-hook-form';

export interface IProps extends Omit<SelectProps, 'error'> {
  name: string;
  defaultValue?: any;
  [x: string]: any;
}

export const AutocompleteController = ({ name, defaultValue, ...rest }: IProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <CustomAutocomplete
            value={value}
            defaultValue={defaultValue}
            onChange={(_: React.SyntheticEvent, newValue: any) => onChange(newValue)}
            helperText={error?.message || ''}
            {...rest}
          />
        );
      }}
    />
  );
};
