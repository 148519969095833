import { Button } from '@mui/material';

import { ActionsPopover } from 'components/popovers/actions-popover';

import { usePopover } from 'components/popovers/popover-wrapper/usePopover';

import { ReactComponent as EditPlanIcon } from 'assets/icons/edit-icon.svg';

import { popperStyles } from 'components/popovers/actions-popover/styles';

export const PlanTableActionsPopper = () => {
  const { anchorEl, handleOpen, handleClose, open } = usePopover();

  return (
    <ActionsPopover
      anchorEl={anchorEl}
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
    >
      <Button sx={popperStyles.actionBtn} startIcon={<EditPlanIcon />} onClick={handleClose}>
        edit plan
      </Button>
    </ActionsPopover>
  );
};
