export const planTypeStyles = {
  box: {
    display: 'inline-block',
    width: '87px',
    padding: '5px 0',
    textAlign: 'center',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif !important',
    fontSize: '10px',
    lineHeight: '14px',
  },

  base: {
    backgroundColor: '#EDECEC',
    color: '#494949',
  },
  enterprice: {
    backgroundColor: '#4D6EF5',
    color: '#FFFFFF',
  },
  custom: {
    backgroundColor: '#D3DCFF',
    color: '#4D6EF5',
  },
  storage: {
    backgroundColor: '#494949',
    color: '#FFF',
  },
};
