import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// components
import PasswordInput from 'components/inputs/password-input';

interface OwnProps {
  name: string;
  label: string;
  withError?: boolean;
  [x: string]: any;
}

const PasswordInputController: React.FC<OwnProps> = ({
  name = '',
  defaultValue = '',
  withError = false,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <PasswordInput
          {...props}
          error={!!error}
          variant="filled"
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          helperText={withError ? error?.message || ' ' : ' '}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

export default memo(PasswordInputController);
