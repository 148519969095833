import React, { FC, ReactNode } from 'react';
// mui components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  styled,
  DialogProps,
} from '@mui/material';
// icons
import { ReactComponent as LogoIcon } from 'assets/icons/logo_dark.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface IStyledDialog extends DialogProps {
  width?: string;
  withoutPadding?: boolean;
}

const StyledDialog = styled(({ withoutPadding, ...props }: IStyledDialog) => <Dialog {...props} />)(
  ({ width, withoutPadding }) => ({
    '& .MuiDialogContent-root': {
      margin: withoutPadding ? 0 : '10px 0 24px',
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
      height: '48px',
      padding: 0,
      '& button': {
        margin: '0 12px',
      },
    },
    '& .MuiDialog-paper': {
      width: width || '480px',
      maxWidth: width || '480px',
      maxHeight: '86vh',
      padding: withoutPadding ? 0 : '24px',
      borderRadius: '8px',
      overflow: 'visible',
      backgroundColor: '#fff',
    },
  })
);

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const StyledDialogTitle: React.FC<DialogTitleProps> = (props) => {
  const { onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
        padding: '0 0 16px 0',
        borderBottom: '2px solid rgba(235, 238, 246, 0.42)',
        '& svg': {
          height: '40px',
          width: 'auto',
        },
      }}
      {...other}
    >
      <LogoIcon />
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            width: '40px',
            height: '40px',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const StyledButton = styled(Button)({
  height: '48px',
  width: '126px',
  textTransform: 'unset',
  boxShadow: 'none',
});

interface IProps {
  open: boolean;
  withCloseButton?: boolean;
  withoutPadding?: boolean;
  withActions?: boolean;
  maxWidth?: string;
  handleClose: () => void;
  children: ReactNode;
}

const DialogModal: FC<IProps> = ({
  open,
  withCloseButton = true,
  withoutPadding,
  withActions,
  maxWidth,
  handleClose,
  children,
}) => {
  return (
    <StyledDialog
      open={open}
      width={maxWidth}
      withoutPadding={withoutPadding}
      onClose={handleClose}
    >
      {withCloseButton && <StyledDialogTitle id="customized-dialog-title" onClose={handleClose} />}
      <DialogContent>{children}</DialogContent>
      {withActions && (
        <DialogActions>
          <StyledButton variant="contained" onClick={handleClose}>
            Close
          </StyledButton>
        </DialogActions>
      )}
    </StyledDialog>
  );
};

export default DialogModal;
