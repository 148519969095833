export const userModalStyles = {
  dialogModal: {
    '&::-webkit-scrollbar': {
      border: 'none',
      width: '3px',
      height: '5px',
      padding: '5px 0',
    },
    '&::-webkit-scrollbar-track': {
      border: 'none',
      height: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '2px',
      borderRadius: '4px',
      cursor: 'pointer',
    },
  },
  formHeader: {
    display: 'flex',
    padding: '40px 40px 20px 40px',
    borderBottom: '1px solid #F3F4F9',
    borderRadius: '12px 12px 0px 0px',

    '@media (max-width: 768px)': {
      justifyContent: 'center',
      textAlign: 'center',
      padding: '15px 15px 15px 15px',
    },
  },
  editText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '136%',
    color: '#23252F',

    '@media (max-width: 768px)': {
      fontSize: 18,
      lineHeight: '20px',
    },
  },
  templateBody: {
    padding: '40px 40px 20px 40px',

    '@media (max-width: 768px)': {
      padding: '15px 15px 15px 15px',
    },
  },
  buttonContainer: { gap: '12px', marginTop: '40px' },
  button: {
    width: '70px',
    height: '48px',
  },
};

export const tabsStyles = {
  tabsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '16px',
    overflowX: 'auto',
    width: '100%',
    maxWidth: '650px',
    marginBottom: '40px',

    '& .MuiTabScrollButton-horizontal.Mui-disabled': {
      display: 'none',
    },
  },

  tabsContent: {
    width: '100%',
  },

  tabList: {
    border: '1px solid #F3F4F9',
    borderRadius: '8px',

    minHeight: 'unset',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& a': {},
    '& a:nth-of-type(1)': {
      borderRight: 'none',
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
    '& a:not(:last-child)': {
      borderRight: '1px solid red',
    },
    '& a:nth-last-of-type(1)': {
      borderLeft: 'none',
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    '& .Mui-selected': {
      backgroundColor: '#E7ECFF',
      color: '#4D6EF5',
    },
  },

  tab: {
    minWidth: '142px',
    minHeight: 'unset',
    padding: '8px 0',
    fontSize: '12px',
    lineHeight: '150%',
    fontWeight: '400',
    color: '#6E707A',
    textTransform: 'capitalize',

    '&:not(:last-child)': {
      borderRight: '1px solid #F3F4F9',
    },

    '@media (max-width: 768px)': {
      minWidth: '155px',
    },
  },

  tabPanel: { padding: '0' },
};
