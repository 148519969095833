import { ReactElement, memo, useRef } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { usePagination } from 'components/table/customized-table-pagination/hooks';
import { useQueries } from 'hooks/useQueries/useQueries';

import { CustomizedTablePagination } from 'components/table/customized-table-pagination';
import { IconMessage } from 'components/icon-message';
import { SkeletonRow } from 'components/table/skeleton-row';

import { IMeta } from 'utils/types';

import { ReactComponent as MessageIcon } from 'assets/icons/error-msg.svg';

import { customTableStyles } from './styles';

interface IProps {
  tableData: any[];
  tableMeta: IMeta;
  headerCells: any[];
  isLoading: boolean;
  renderRow: (data: any) => ReactElement;
}

const INITIAL_ITEMS_PER_PAGE = 10;

export const CustomTable = memo(
  ({ tableData, tableMeta, headerCells, isLoading, renderRow }: IProps) => {
    const { setQueries } = useQueries();

    const { page, rowsPerPage, handlePageChange, handleSelectPage, handleRowsPerPageChange } =
      usePagination(tableMeta, INITIAL_ITEMS_PER_PAGE, afterPagination);

    const tableRef = useRef<any>(null);

    const scrollToTable = () =>
      setTimeout(() => tableRef.current.scrollIntoView({ block: 'start', inline: 'nearest' }));

    function afterPagination(data: { itemsPerPage: number } | { page: number }) {
      setQueries(data);
      scrollToTable();
    }

    if (!isLoading && !tableData?.length) {
      return (
        <IconMessage
          icon={<MessageIcon width={150} height={150} />}
          text1={{ text: 'No data found!', size: '24px' }}
        />
      );
    }

    return (
      <>
        <TableContainer component={Paper} sx={customTableStyles.tableContainer} ref={tableRef}>
          <Table sx={customTableStyles.table} aria-label="simple table">
            <TableHead>
              <TableRow sx={customTableStyles.tableHeader}>
                {headerCells.map((cell: string, index: number) => (
                  <TableCell align="left" key={index}>
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? Array.from({ length: 10 }).map((_, index) => (
                    <SkeletonRow length={headerCells.length} key={index} />
                  ))
                : tableData?.map((rowData: any) => renderRow(rowData))}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomizedTablePagination
          rowsPerPageOptions={[10, 25, 50]}
          count={tableMeta?.totalItems || 0}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          totalPages={tableMeta?.totalPages}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSelectPage={handleSelectPage}
        />
      </>
    );
  }
);
