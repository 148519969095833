export const skeletonRowStyles = {
  tableRow: {
    '& .MuiTableCell-body': {
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#23252F',
      border: '1px solid #F3F4F9',
    },
  },
};
