import { Fragment } from 'react';

import { Grid } from '@mui/material';

import { PlanUserVisibility } from '../plan-user-visibility';

import { OutlinedInputController } from 'controllers/outlined-input-controller';
import { SelectController } from 'controllers/select-controller';
import { NumericFormatInputController } from 'controllers/numeric-format-input-controller';

import { useFormContext, useWatch } from 'react-hook-form';

import { PlanTypeEnum, paymentFrequencyFields, planTypesFields } from 'pages/plans/utils';
import { amoutInputConfig, isAllowedStorage } from './utils';

import { planModalBodyStyles } from './styles';

export const PlanModalBody = () => {
  const { control, unregister, resetField } = useFormContext();

  const type = useWatch({ control, name: 'type' });

  const additionalOnChange = () => {
    resetField('name');
    [
      'ofProjects',
      'ofUsers',
      'monthlyAmount',
      'annualAmount',
      'paymentFrequency',
      'amount',
      'storagePlan',
      'userVisibility',
    ].forEach((field: string) => unregister(field));
  };

  const sharedInputFields = () => {
    return (
      <Grid sx={planModalBodyStyles.amountContainer}>
        <NumericFormatInputController
          withError
          allowNegative={false}
          fullWidth
          type="tel"
          label="# of Projects"
          name={'ofProjects'}
          placeholder="# of Projects"
        />
        <NumericFormatInputController
          withError
          allowNegative={false}
          fullWidth
          type="tel"
          label="# of Users"
          name={'ofUsers'}
          placeholder="# of Users"
        />
      </Grid>
    );
  };

  const sharedStorageAmountField = () => {
    return (
      <NumericFormatInputController
        showEndAdornment
        isAllowed={isAllowedStorage}
        adornmentContent="GB"
        withError
        allowNegative={false}
        fullWidth
        type="tel"
        label="Storage Plan"
        name={'storagePlan'}
        placeholder="Storage Plan"
      />
    );
  };

  const renderBasePlanFields = () => {
    return (
      <Fragment key={PlanTypeEnum.BASE_PLAN}>
        {sharedInputFields()}
        <Grid sx={planModalBodyStyles.amountContainer}>
          <NumericFormatInputController
            {...amoutInputConfig}
            label="Monthly Amount"
            name={'monthlyAmount'}
          />
          <NumericFormatInputController
            {...amoutInputConfig}
            label="Annual Amount"
            name={'annualAmount'}
          />
        </Grid>
      </Fragment>
    );
  };

  const renderCustomPlanFields = () => {
    return (
      <Fragment key={PlanTypeEnum.CUSTOM_PLAN}>
        {sharedInputFields()}
        <Grid sx={planModalBodyStyles.amountContainer}>
          <NumericFormatInputController {...amoutInputConfig} label="Amount" name={'amount'} />
          <SelectController
            withError
            label="Payment Frequency"
            name={'paymentFrequency'}
            placeholder="Payment Frequency"
            options={paymentFrequencyFields}
            defaultValue=""
          />
        </Grid>
        {sharedStorageAmountField()}
        <PlanUserVisibility />
      </Fragment>
    );
  };

  const renderStoragePlanFields = () => {
    return <Fragment key={PlanTypeEnum.STORAGE_PLAN}>{sharedStorageAmountField()}</Fragment>;
  };

  return (
    <Grid sx={planModalBodyStyles.container}>
      <SelectController
        additionalOnChange={additionalOnChange}
        options={planTypesFields}
        defaultValue={planTypesFields[0].value}
        label="Plan Type"
        name={'type'}
        placeholder="Plan Type"
      />
      <OutlinedInputController
        withError
        name="name"
        label="Plan Name"
        defaultValue=""
        fullWidth
        placeholder="Plan Name"
      />
      {type === PlanTypeEnum.BASE_PLAN && renderBasePlanFields()}
      {type === PlanTypeEnum.CUSTOM_PLAN && renderCustomPlanFields()}
      {type === PlanTypeEnum.STORAGE_PLAN && renderStoragePlanFields()}
    </Grid>
  );
};
