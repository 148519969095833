export const userTableStyles = {
  tableRow: {
    '& .MuiTableCell-body': {
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#23252F',
      border: '1px solid #F3F4F9',
    },
  },
  firstName: {
    minWidth: '190px',
    maxWidth: '190px',
    width: '190px',
    wordBreak: 'break-word',
  },
  lastName: {
    minWidth: '126px',
    maxWidth: '126px',
    width: '126px',
    wordBreak: 'break-word',
  },
  email: {
    minWidth: '219px',
    maxWidth: '219px',
    width: '219px',
    wordBreak: 'break-word',
  },
  creationDate: {
    minWidth: '194px',
    maxWidth: '194px',
    width: '194px',
    wordBreak: 'break-word',
  },
  type: {
    minWidth: '139px',
    maxWidth: '139px',
    width: '139px',
    wordBreak: 'break-word',
  },
  planName: {
    minWidth: '110px',
    maxWidth: '110px',
    width: '110px',
    wordBreak: 'break-word',
  },
  planStatus: {
    minWidth: '110px',
    maxWidth: '110px',
    width: '110px',
    wordBreak: 'break-word',
  },
  action: {
    padding: 0,
    minWidth: '48px',
    maxWidth: '48px',
    width: '48px',
  },
};
