import React, { useCallback, useState } from 'react';
// mui components
import { InputAdornment, InputAdornmentProps, FilledTextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import BaseInput from './base-input';

const StyledEndAdornment = styled((props: InputAdornmentProps) => <InputAdornment {...props} />)(
  () => ({
    cursor: 'pointer',
    '& p': {
      color: '#535F77',
    },
  })
);

const PasswordInput: React.FC<FilledTextFieldProps> = (props) => {
  const [showPass, setShowPass] = useState(false);

  const handleChangeShow = useCallback(() => {
    setShowPass((prevState) => !prevState);
  }, []);

  return (
    <BaseInput
      {...props}
      type={showPass ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <StyledEndAdornment position="end" onClick={handleChangeShow}>
            {showPass ? 'Hide' : 'Show'}
          </StyledEndAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
