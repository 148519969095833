import { SelectController } from 'controllers/select-controller';

export const StorageDetails = () => {
  const storageFields = [{ id: 0, label: '100GB', value: 100 }];

  return (
    <>
      <SelectController
        placeholder="Storage Amount"
        required
        fullWidth
        withError
        name="storageAmount"
        label="Storage Amount"
        options={storageFields}
        defaultValue=""
      />
    </>
  );
};
