import { useEffect, useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { useGetPlansQuery } from 'redux/apis/users/users-api';

import { Grid } from '@mui/material';

import { SelectController } from 'controllers/select-controller';
import DatePickerController from 'controllers/date-picker-controller';

import { datePickerDefaultState, planStatusDefaultState } from './form-config/data';

import { findPlanById, planNamesAdapter } from './utils';
import { configurePaymentDetailsForm } from './form-config/utils';

import { PlanNameEnum, PlanStatusEnum } from 'pages/users/entities';

import { IUserPlan } from 'utils/types';

import { planDetailsStyles } from './styles';

export const PlanDetails = ({ userPlan }: { userPlan: IUserPlan | null }) => {
  const plans = useGetPlansQuery()?.data?.items || [];
  const { control, setValue } = useFormContext();

  const isPlanExist = !!userPlan;
  const userTrialEndDate = userPlan?.trialEndDate;
  const userNextChargeDate = userPlan?.nextChargeDate;
  const isPlanPending = userPlan?.isPendingForCancel;
  const isFirstProjectFree = userPlan?.name === PlanNameEnum.FIRST_PROJECT_FREE;
  const isLifeTimeDeal = userPlan?.name === PlanNameEnum.LIFETIME_DEAL;

  const tempPlanName = useWatch({ control: control, name: 'tempPlanName' });
  const { prevTempPlanStatus = '', currentTempPlanStatus = '' } =
    useWatch({
      control: control,
      name: 'tempPlanStatus',
    }) || {};

  const [plansNameProps, setPlansNameProps] = useState({
    data: planNamesAdapter(plans),
    disabled: true,
  });
  const [plansStatusProps, setPlansStatusProps] = useState(planStatusDefaultState);
  const [datePickerProps, setDatePickerProps] = useState(datePickerDefaultState);

  useEffect(() => {
    if (isPlanExist && !isFirstProjectFree) {
      configurePaymentDetailsForm(
        {
          planName: tempPlanName,
          planStatus: currentTempPlanStatus,
          prevPlanStatus: prevTempPlanStatus,
        },
        { userTrialEndDate, userNextChargeDate },
        { setFormValue: setValue },
        { setPlansNameProps, setPlansStatusProps, setDatePickerProps }
      );
    }
  }, [
    isPlanExist,
    isFirstProjectFree,
    tempPlanName,
    currentTempPlanStatus,
    prevTempPlanStatus,
    userTrialEndDate,
    userNextChargeDate,
  ]);

  const onAdditionalPlanNameChange = (id: number) => {
    const selectedPlan = findPlanById(plans, id);
    setValue('tempPlanName', selectedPlan?.name);
  };

  const onAdditionalPlanStatusChange = (status: PlanStatusEnum) => {
    setValue('tempPlanStatus', {
      prevTempPlanStatus: currentTempPlanStatus,
      currentTempPlanStatus: status,
    });
  };

  const getPlanInfoMessage = () => {
    if (!isPlanExist) return 'The user is without any plan.';
    if (isFirstProjectFree) return 'The user has the First Project Free plan.';
    if (isLifeTimeDeal) return 'The user has the Life Time Deal plan.';
    if (isPlanPending) return `The user's plan is pending.`;
  };

  if (!isPlanExist || isFirstProjectFree || isLifeTimeDeal) {
    const planInfoMessage = getPlanInfoMessage();
    return (
      <Grid sx={planDetailsStyles.userPlanInfo}>
        {`${planInfoMessage}\nThere are no details to update.`}
      </Grid>
    );
  }

  return (
    <Grid sx={planDetailsStyles.container}>
      <SelectController
        required
        fullWidth
        withError
        disabled={plansNameProps.disabled}
        placeholder="Plan Name"
        name={'plan.productId'}
        label="Plan Name"
        options={plansNameProps.data}
        additionalOnChange={onAdditionalPlanNameChange}
      />
      <SelectController
        required
        fullWidth
        withError
        disabled={plansStatusProps.disabled}
        placeholder="Plan Status"
        name={'plan.status'}
        label="Plan Status"
        options={plansStatusProps.data}
        additionalOnChange={onAdditionalPlanStatusChange}
      />
      {datePickerProps.isVisible && (
        <DatePickerController
          withError
          fullWidth
          name={datePickerProps.name}
          label={datePickerProps.label}
          key={datePickerProps.key}
          minDate={datePickerProps.minDate}
        />
      )}
    </Grid>
  );
};
