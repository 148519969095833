import { memo } from 'react';

import { Divider, Grid } from '@mui/material';

import { Search } from 'components/search';
import { DropdownButton } from 'components/dropdown-button';
import { SelectPopover } from 'components/popovers/select-popover';

import { ReactComponent as SortingIcon } from 'assets/icons/sorting-icon.svg';
import { ReactComponent as FilteringIcon } from 'assets/icons/filter.svg';

import { tableTopBarStyles } from './styles';

interface IProps {
  withQuery?: boolean;
  fields?: { sortFields?: any; filterFields?: any; addFields?: any };
  defaultValues?: { sort: any; filter: any; search: string };
  getAddValue?: (data: string) => void;
  getSortingValues?: (data: any) => void;
  getFilteringValues?: (data: any) => void;
  onSortingClear?: (data: any) => void;
  onFilteringClear?: (data: any) => void;
  getDebouncedSearch?: (value: string) => void;
}

export const TableTopBar = memo(
  ({
    fields,
    defaultValues,
    getAddValue,
    getDebouncedSearch,
    getSortingValues,
    getFilteringValues,
    onSortingClear,
    onFilteringClear,
  }: IProps) => {
    const isSearchExist = !!getDebouncedSearch;
    const isFilteringExist = fields?.filterFields && !!getFilteringValues;
    const isSortingExist = fields?.sortFields && !!getSortingValues;
    const isAddExist = fields?.addFields && !!getAddValue;

    return (
      <Grid sx={tableTopBarStyles.container}>
        {isSearchExist && (
          <Search
            maxWidth="216px"
            getDebouncedSearchValue={getDebouncedSearch}
            defaultSearchValue={defaultValues!.search}
          />
        )}
        {isSortingExist && (
          <DropdownButton
            icon={<SortingIcon />}
            buttonText="Sorting"
            defaultValues={defaultValues!.sort}
            fields={fields.sortFields}
            getValues={getSortingValues}
            onClear={onSortingClear}
          />
        )}
        {isFilteringExist && (
          <DropdownButton
            icon={<FilteringIcon />}
            buttonText="Filtering"
            defaultValues={defaultValues!.filter}
            fields={fields.filterFields}
            getValues={getFilteringValues}
            onClear={onFilteringClear}
          />
        )}
        {isAddExist && (
          <>
            <Divider sx={tableTopBarStyles.divider} />
            <SelectPopover buttonText="Add new" onSelect={getAddValue} items={fields.addFields} />
          </>
        )}
      </Grid>
    );
  }
);
