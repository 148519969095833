import { useEffect, useState } from 'react';

import { SelectChangeEvent } from '@mui/material';

import { IMeta } from 'utils/types';

export const usePagination = (
  meta: IMeta,
  initialItemsPerPage: number,
  afterPagination?: (data?: any) => void
) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(initialItemsPerPage);

  useEffect(() => {
    if (meta) {
      setPage(meta.currentPage);
      setRowsPerPage(meta.itemsPerPage);
    }
  }, [JSON.stringify(meta)]);

  const handlePageChange = (newPage: number) => {
    if (newPage < 0) return;
    setPage(newPage + 1);
    if (afterPagination) afterPagination({ page: newPage + 1 });
  };

  const handleSelectPage = (event: SelectChangeEvent) => {
    const newSelectedPage = parseInt(event.target.value, 10);
    if (newSelectedPage === page) return;
    setPage(newSelectedPage);
    if (afterPagination) afterPagination({ page: newSelectedPage });
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    if (newRowsPerPage === rowsPerPage) return;
    setRowsPerPage(newRowsPerPage);
    setPage(1); // Reset to first page when changing rowsPerPage
    if (afterPagination) afterPagination({ itemsPerPage: newRowsPerPage, page: 1 });
  };

  return {
    page,
    rowsPerPage,
    handlePageChange,
    handleSelectPage,
    handleRowsPerPageChange,
  };
};
