import { useCallback } from 'react';
// custom hooks
import { ICompanyProfile, useUserData } from './useUserData';
// api
import api from 'utils/api';

export const useAuth = () => {
  const { updateUserData, handleSetPending } = useUserData();

  const getUserInfo = useCallback(async () => {
    try {
      const profileResult = await api.auth.getUserProfile();

      updateUserData({
        companyProfile: profileResult as ICompanyProfile,
      });
    } catch {
      updateUserData({ companyProfile: undefined });
    }
  }, [updateUserData]);

  const handleAuth = useCallback(async () => {
    handleSetPending(true);
    await getUserInfo();
    handleSetPending(false);
  }, [getUserInfo, handleSetPending]);

  return { handleAuth };
};
