import { memo, useState } from 'react';

import { Grid } from '@mui/material';
import { AutocompleteController } from 'controllers/autocomplete-controller';

import { Tag } from 'components/tag';

import { useFormContext } from 'react-hook-form';

import { useGetUsersQuery } from 'redux/apis/users/users-api';

import { planUserVisibilityStyles } from './styles';

export const PlanUserVisibility = memo(() => {
  const [searchValue, setSearchValue] = useState('');

  const { setValue } = useFormContext();

  const { data, isLoading, isFetching } = useGetUsersQuery({
    itemsPerPage: '10',
    page: '1',
    ...(searchValue && { search: searchValue }),
  });

  const filterSelectedTags = (id: number, values: any) => {
    const filteredTags = values.filter((item: any) => item.id !== id);
    setValue('userVisibility', filteredTags);
  };

  const getDebouncedSearchValue = (value: string) => setSearchValue(value);

  const isOptionEqualToValue = (option: any, optionValue: any) => option?.id === optionValue?.id;

  const getOptionLabel = (option: any) => {
    return `${option?.firstName} ${option?.lastName}`;
  };

  const renderOptionContent = (option: any) => `${option?.firstName} ${option?.lastName}`;

  const renderSelectedTags = (values: any) => {
    return values.map((value: any) => (
      <Tag
        text={`${value.firstName} ${value.lastName}`}
        onDelete={() => filterSelectedTags(value.id, values)}
        key={value.id}
      />
    ));
  };

  return (
    <Grid sx={planUserVisibilityStyles.container}>
      <AutocompleteController
        multiple
        fullWidth
        disableClearable
        disableCloseOnSelect
        label="Plan Visible to Users"
        placeholder="Select Users"
        name="userVisibility"
        defaultValue={[]}
        options={data?.items || []}
        isLoading={isLoading || isFetching}
        renderOptionContent={renderOptionContent}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        getDebouncedSearchValue={getDebouncedSearchValue}
        renderSelectedTags={renderSelectedTags}
      />
    </Grid>
  );
});
