import { useCallback, useState } from 'react';

import { useGetUsersQuery, useLoginAsUserMutation } from 'redux/apis/users/users-api';

import { useQueries } from 'hooks/useQueries/useQueries';

import { Grid } from '@mui/material';

import { UserTableTopBar } from './components/user-table-top-bar';
import { UserTable } from './components/user-table';
import { UserModal } from './components/user-modal';
import { IconMessage } from 'components/icon-message';

import { ReactComponent as MessageIcon } from 'assets/icons/error-msg.svg';

import { userStyles } from './styles';

export const Users: React.FC = () => {
  const { parsedQueries } = useQueries();

  const { data, isLoading, isFetching, isError } = useGetUsersQuery(parsedQueries);

  const [loginAsUser] = useLoginAsUserMutation();

  const [user, setUser] = useState(null);

  const handleLoginAsUser = useCallback(
    async (email: string) => {
      try {
        const token: any = await loginAsUser({ email });
        const inviteUrl = `${process.env.REACT_APP_DOMAIN}sign-up-invite?entryToken=${token?.data?.entryToken}`;
        window.open(inviteUrl, '_blank')!.focus();
      } catch (error: any) {
        console.log('Error:', error);
      }
    },
    [loginAsUser]
  );

  const handleEditUser = useCallback((users) => setUser(users), []);

  const handleCloseUserEditModal = useCallback(() => setUser(null), []);

  return (
    <Grid sx={userStyles.container}>
      {isError ? (
        <IconMessage
          icon={<MessageIcon width={150} height={150} />}
          text1={{ text: 'Something Went Wrong,', size: '24px' }}
          text2={{ text: 'Please Try Again!', size: '18px' }}
        />
      ) : (
        <>
          <UserTableTopBar />
          <UserTable
            handleEditUser={handleEditUser}
            handleLoginAsUser={handleLoginAsUser}
            usersList={data}
            isLoading={isLoading || isFetching}
          />
          {user && <UserModal open handleClose={handleCloseUserEditModal} user={user} />}
        </>
      )}
    </Grid>
  );
};
