export const userDetailsStyles = {
  above: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',

    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  below: {
    marginTop: '16px',
  },
};
