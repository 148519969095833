import { CustomSwitch } from 'components/inputs/custom-switch';

import { Controller, useFormContext } from 'react-hook-form';

export const SwitchController = ({
  name = '',
  shouldUnregister = false,
  withTick,
  disabled,
  isSmall,
}: any) => {
  const { control } = useFormContext();

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <CustomSwitch
            isSmall={isSmall}
            withTick={withTick}
            disabled={disabled}
            checked={!!value}
            onChange={onChange}
          />
        );
      }}
    />
  );
};
